import { is } from "@babel/types";
import React, { memo, useCallback, useEffect, useState } from "react";
import { ButtonListItem } from "./index";
import { createUseStyles } from "react-jss";
// import { Button } from 'antd';
import Button from "react-bootstrap/Button";

import ReactEmoji from "react-emoji";
import Emojify from "react-emojione";

const ChatListButtonsBox = ({
  buttons,
  onSelectText,
  messageListLength,
  index,
  onEndSessionFromBackend,
}) => {
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  const style = useStyle({ isButtonSelected });

  const onPressButton = (item) => {
    setIsButtonSelected(true);

    // if (item.text !== 'undefined' && item.text) {
    //     setTimeout(() => {
    //         onSelectText(item.text)
    //     }, 1000)
    // }
    setTimeout(() => {
      if (item.hasOwnProperty("is_session_ended")) {
        if (item.is_session_ended) onEndSessionFromBackend();
      } else {
        if (item.text !== "undefined" && item.text) onSelectText(item.text);
      }
    }, 1000);
  };

  const ListItem = memo(({ item, index }) => {
    return (
      <li key={index} className={style.listContainer}>
        {
          !item?.text.includes(":") ? (
            <Button
              variant="outline-primary"
              className={style.button}
              id="optionBtns"
              onClick={() => {
                onPressButton(item);
              }}
            >
              {item?.text}
            </Button>
          ) : (
            <Emojify
              style={{ height: "50%", width: "50%" }}
              onClick={() => {
                onPressButton(item);
              }}
            >
              <span className={style.emojiButon}>{item.text}</span>
            </Emojify>
          )
          // <text className={style.buttonEmoji} onClick={() => { onPressButton(item) }}>{ReactEmoji.emojify(item?.text)}</text>
        }
      </li>
    );
  });

  const List = ({ data }) => {
    const listItems = data.map((item, index) => (
      <ListItem item={item} index={index} />
    ));

    return <ul className={style.listMainContainer}>{listItems}</ul>;
  };

  return (
    <>
      {messageListLength - 1 === index && (
        <div className={style.container}>
          <div className={style.list}>
            <List data={buttons} />
          </div>
        </div>
      )}
    </>
  );
};

const useStyle = createUseStyles({
  container: {
    width: "100%",
    backgroundColor: "transparent",
    display: (props) => (props.isButtonSelected ? "none" : "flex"),
  },
  list: {
    width: "100%",
    backgroundColor: "transparent",
    alignSelf: "center",
    bottom: "10px",
  },
  listMainContainer: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    flexWrap: "wrap",
  },
  listContainer: {
    // display:'-webkit-flex',
    // columns: '2'
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    borderColor: "#429EFA",
    borderWidth: "0px",
    // paddingRight: '2vw',
    // paddingLeft: '2vw',
    paddingTop: "1vw",
    paddingBottom: "1vw",
    margin: "0.7vh",
    borderRadius: "5vw",
    fontSize: "12px",
    color: "#429EFA",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    fontWeight: "normal",
    minWidth: "fit-content",
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 40%) 0px 8px 8px -5px, rgb(74 79 97 / 35%) 0px 8px 8px -8px",
    mozBoxShadow:
      "rgb(73 78 96 / 40%) 0px 8px 8px -5px, rgb(74 79 97 / 35%) 0px 8px 8px -8px",
    boxShadow:
      "rgb(73 78 96 / 40%) 0px 8px 8px -5px, rgb(74 79 97 / 35%) 0px 8px 8px -8px",
    transition: "all 1s ease-in-out",
    "&:-moz-transition": "all 1s ease-in-out",
    "&:-webkit-transition": "all 1s ease-in-out",
    "&:-o-transition": "all 1s ease-in-out",

    // '&:hover': {
    //     fontSize: '1.2vw',
    //     fontWeight: 'bold',

    //     transition: "all 0.5s ease-in-out",
    //     "&:-moz-transition": "all 0.5s ease-in-out",
    //     "&:-webkit-transition": "all 0.5s ease-in-out",
    //     "&:-o-transition": "all 0.5s ease-in-out",

    // },

    wordWrap: "break-word",
  },
  buttonEmoji: {
    // borderColor: '#429EFA',
    borderWidth: "0px",
    paddingRight: "1vh",
    paddingLeft: "1vh",
    paddingTop: "0.8vh",
    paddingBottom: "0.8vh",
    margin: "1%",
    borderRadius: "5vh",
    color: "#429EFA",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF50",
    fontSize: "2vh",
  },
  text: {
    fontSize: "3vw",
    color: "#429EFA",
    maxWidth: "18vw",
    fontWeight: "300",
    alignSelf: "center",
  },
  emojiButonContainer: {
    backgroundColor: "#00000050",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  emojiButon: {
    // backgroundColor:'#FFFFFF20',
    alignSelf: "center",
    padding: "1vh",
    cursor: "pointer",
  },
});

export default memo(ChatListButtonsBox);
