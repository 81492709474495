import React from "react";
import {Row, Col} from "react-bootstrap";
import InputField from './../formFields/inputField';
import SelectField from "../formFields/selectField";
import PhoneDropdown from "../formFields/phoneDropdown";

const countries = [
    {
        value: 'None',
        label: 'Select your country'
    },
    {
        value: 'Afghanistan',
        label: 'Afghanistan'
    },
    {
        value: 'Albania',
        label: 'Albania'
    },
    {
        value: 'Albania',
        label: 'Albania',
    },
    {
        value: 'Algeria',
        label: 'Algeria',
    },
    {
        value: 'Andorra',
        label: 'Andorra',
    },
    {
        value: 'Andorra',
        label: 'Andorra',
    },
    {
        value: 'Angola',
        label: 'Angola',
    },
    {
        value: 'Antigua and Barbuda',
        label: 'Antigua and Barbuda',
    },
    {
        value: 'Argentina',
        label: 'Argentina',
    },
    {
        value: 'Armenia',
        label: 'Armenia',
    },
    {
        value: 'Australia',
        label: 'Australia',
    },
    {
        value: 'Austria',
        label: 'Austria',
    },
    {
        value: 'Azerbaijan',
        label: 'Azerbaijan',
    },
    {
        value: 'Bahamas',
        label: 'Bahamas'
    },
    {
        value: 'Bahrain',
        label: 'Bahrain',
    },
    {
        value: 'Bangladesh',
        label: 'Bangladesh',
    },
    {
        value: 'Barbados',
        label: 'Barbados',
    },
    {
        value: 'Belarus',
        label: 'Belarus',
    },
    {
        value: 'Belgium',
        label: 'Belgium',
    },
    {
        value: 'Belize',
        label: 'Belize',
    },
    {
        value: 'Benin',
        label: 'Benin',
    },
    {
        value: 'Bhutan',
        label: 'Bhutan',
    },
    {
        value: 'Bolivia',
        label: 'Bolivia',
    },
    {
        value: 'Bosnia and Herzegovina',
        label: 'Bosnia and Herzegovina',
    },
    {
        value: 'Botswana',
        label: 'Botswana',
    },
    {
        value: 'Brazil',
        label: 'Brazil',
    },
    {
        value: 'Brunei',
        label: 'Brunei',
    },
    {
        value: 'Bulgaria',
        label: 'Bulgaria',
    },
    {
        value: 'Burkina Faso',
        label: 'Burkina Faso',
    },
    {
        value: 'Burundi',
        label: 'Burundi',
    },
    {
        value: 'Cambodia',
        label: 'Cambodia',
    },
    {
        value: 'Cameroon',
        label: 'Cameroon',
    },
    {
        value: 'Canada',
        label: 'Canada',
    },
    {
        value: 'Cape Verde',
        label: 'Cape Verde',
    },
    {
        value: 'Cayman Islands',
        label: 'Cayman Islands',
    },
    {
        value: 'Central African Republic',
        label: 'Central African Republic',
    },
    {
        value: 'Chad',
        label: 'Chad',
    },
    {
        value: 'Chile',
        label: 'Chile',
    },
    {
        value: 'China',
        label: 'China',
    },
    {
        value: 'China - Hong Kong / Macau',
        label: 'China - Hong Kong / Macau',
    },
    {
        value: 'Colombia',
        label: 'Colombia',
    },
    {
        value: 'Comoros',
        label: 'Comoros',
    },
    {
        value: 'Congo',
        label: 'Congo',
    },
    {
        value: 'Congo, Democratic Republic of (DRC)',
        label: 'Congo, Democratic Republic of (DRC)',
    },
    {
        value: 'Costa Rica',
        label: 'Costa Rica',
    },
    {
        value: 'Croatia',
        label: 'Croatia',
    },
    {
        value: 'Cuba',
        label: 'Cuba',
    },
    {
        value: 'Cyprus',
        label: 'Cyprus',
    },
    {
        value: 'Czech Republic',
        label: 'Czech Republic',
    },
    {
        value: 'Denmark',
        label: 'Denmark',
    },
    {
        value: 'Djibouti',
        label: 'Djibouti',
    },
    {
        value: 'Dominica',
        label: 'Dominica',
    },
    {
        value: 'Dominican Republic',
        label: 'Dominican Republic',
    },
    {
        value: 'Ecuador',
        label: 'Ecuador',
    },
    {
        value: 'Egypt',
        label: 'Egypt',
    },
    {
        value: 'El Salvador',
        label: 'El Salvador',
    },
    {
        value: 'Equatorial Guinea',
        label: 'Equatorial Guinea',
    },
    {
        value: 'Eritrea',
        label: 'Eritrea',
    },
    {
        value: 'Estonia',
        label: 'Estonia',
    },
    {
        value: 'Eswatini',
        label: 'Eswatini',
    },
    {
        value: 'Ethiopia',
        label: 'Ethiopia',
    },
    {
        value: 'Fiji',
        label: 'Fiji',
    },
    {
        value: 'Finland',
        label: 'Finland',
    },
    {
        value: 'France',
        label: 'France',
    },
    {
        value: 'French Guiana',
        label: 'French Guiana',
    },
    {
        value: 'Gabon',
        label: 'Gabon',
    },
    {
        value: 'Gambia, Republic of The',
        label: 'Gambia, Republic of The',
    },
    {
        value: 'Georgia',
        label: 'Georgia',
    },
    {
        value: 'Germany',
        label: 'Germany',
    },
    {
        value: 'Ghana',
        label: 'Ghana',
    },
    {
        value: 'Great Britain',
        label: 'Great Britain',
    },
    {
        value: 'Greece',
        label: 'Greece',
    },
    {
        value: 'Grenada',
        label: 'Grenada',
    },
    {
        value: 'Guadeloupe',
        label: 'Guadeloupe',
    },
    {
        value: 'Guatemala',
        label: 'Guatemala',
    },
    {
        value: 'Guinea',
        label: 'Guinea',
    },
    {
        value: 'Guinea-Bissau',
        label: 'Guinea-Bissau',
    },
    {
        value: 'Guyana',
        label: 'Guyana',
    },
    {
        value: 'Haiti',
        label: 'Haiti',
    },
    {
        value: 'Honduras',
        label: 'Honduras',
    },
    {
        value: 'Hungary',
        label: 'Hungary',
    },
    {
        value: 'Iceland',
        label: 'Iceland',
    },
    {
        value: 'India',
        label: 'India',
    },
    {
        value: 'Indonesia',
        label: 'Indonesia',
    },
    {
        value: 'Iran',
        label: 'Iran',
    },
    {
        value: 'Iraq',
        label: 'Iraq',
    },
    {
        value: 'Israel and the Occupied Territories',
        label: 'Israel and the Occupied Territories',
    },
    {
        value: 'Italy',
        label: 'Italy',
    },
    {
        value: "Ivory Coast (Cote d'Ivoire)",
        label: "Ivory Coast (Cote d'Ivoire)",
    },
    {
        value: 'Jamaica',
        label: 'Jamaica',
    },
    {
        value: 'Japan',
        label: 'Japan',
    },
    {
        value: 'Jordan',
        label: 'Jordan',
    },
    {
        value: 'Kazakhstan',
        label: 'Kazakhstan',
    },
    {
        value: 'Kenya',
        label: 'Kenya',
    },
    {
        value: 'Korea, Democratic Republic of (North Korea)',
        label: 'Korea, Democratic Republic of (North Korea)',
    },
    {
        value: 'Korea, Republic of (South Korea)',
        label: 'Korea, Republic of (South Korea)',
    },
    {
        value: 'Kosovo',
        label: 'Kosovo',
    },
    {
        value: 'Kuwait',
        label: 'Kuwait',
    },
    {
        value: 'Kyrgyz Republic (Kyrgyzstan)',
        label: 'Kyrgyz Republic (Kyrgyzstan)',
    },
    {
        value: 'Laos',
        label: 'Laos',
    },
    {
        value: 'Latvia',
        label: 'Latvia',
    },
    {
        value: 'Lebanon',
        label: 'Lebanon',
    },
    {
        value: 'Lesotho',
        label: 'Lesotho',
    },
    {
        value: 'Liberia',
        label: 'Liberia',
    },
    {
        value: 'Libya',
        label: 'Libya',
    },
    {
        value: 'Liechtenstein',
        label: 'Liechtenstein',
    },
    {
        value: 'Lithuania',
        label: 'Lithuania',
    },
    {
        value: 'Luxembourg',
        label: 'Luxembourg',
    },
    {
        value: 'Madagascar',
        label: 'Madagascar',
    },
    {
        value: 'Malawi',
        label: 'Malawi',
    },
    {
        value: 'Malaysia',
        label: 'Malaysia',
    },
    {
        value: 'Maldives',
        label: 'Maldives',
    },
    {
        value: 'Mali',
        label: 'Mali',
    },
    {
        value: 'Malta',
        label: 'Malta',
    },
    {
        value: 'Martinique',
        label: 'Martinique',
    },
    {
        value: 'Mauritania',
        label: 'Mauritania',
    },
    {
        value: 'Mauritius',
        label: 'Mauritius',
    },
    {
        value: 'Mayotte',
        label: 'Mayotte',
    },
    {
        value: 'Mexico',
        label: 'Mexico',
    },
    {
        value: 'Moldova, Republic of',
        label: 'Moldova, Republic of',
    },
    {
        value: 'Monaco',
        label: 'Monaco',
    },
    {
        value: 'Mongolia',
        label: 'Mongolia',
    },
    {
        value: 'Montenegro',
        label: 'Montenegro',
    },
    {
        value: 'Montserrat',
        label: 'Montserrat',
    },
    {
        value: 'Morocco',
        label: 'Morocco',
    },
    {
        value: 'Mozambique',
        label: 'Mozambique',
    },
    {
        value: 'Myanmar/Burma',
        label: 'Myanmar/Burma',
    },
    {
        value: 'Namibia',
        label: 'Namibia',
    },
    {
        value: 'Nepal',
        label: 'Nepal',
    },
    {
        value: 'Netherlands',
        label: 'Netherlands',
    },
    {
        value: 'New Zealand',
        label: 'New Zealand',
    },
    {
        value: 'Nicaragua',
        label: 'Nicaragua',
    },
    {
        value: 'Niger',
        label: 'Niger',
    },
    {
        value: 'Nigeria',
        label: 'Nigeria',
    },
    {
        value: 'North Macedonia, Republic of',
        label: 'North Macedonia, Republic of',
    },
    {
        value: 'Norway',
        label: 'Norway',
    },
    {
        value: 'Oman',
        label: 'Oman',
    },
    {
        value: 'Pacific Islands',
        label: 'Pacific Islands',
    },
    {
        value: 'Pakistan',
        label: 'Pakistan',
    },
    {
        value: 'Panama',
        label: 'Panama',
    },
    {
        value: 'Papua New Guinea',
        label: 'Papua New Guinea',
    },
    {
        value: 'Paraguay',
        label: 'Paraguay',
    },
    {
        value: 'Peru',
        label: 'Peru',
    },
    {
        value: 'Philippines',
        label: 'Philippines',
    },
    {
        value: 'Poland',
        label: 'Poland',
    },
    {
        value: 'Portugal',
        label: 'Portugal',
    },
    {
        value: 'Puerto Rico',
        label: 'Puerto Rico',
    },
    {
        value: 'Qatar',
        label: 'Qatar',
    },
    {
        value: 'Reunion',
        label: 'Reunion',
    },
    {
        value: 'Romania',
        label: 'Romania',
    },
    {
        value: 'Russian Federation',
        label: 'Russian Federation',
    },
    {
        value: 'Rwanda',
        label: 'Rwanda',
    },
    {
        value: 'Saint Kitts and Nevis',
        label: 'Saint Kitts and Nevis',
    },
    {
        value: 'Saint Lucia',
        label: 'Saint Lucia',
    },
    {
        value: 'Saint Vincent and the Grenadines',
        label: 'Saint Vincent and the Grenadines',
    },
    {
        value: 'Samoa',
        label: 'Samoa',
    },
    {
        value: 'Sao Tome and Principe',
        label: 'Sao Tome and Principe',
    },
    {
        value: 'Saudi Arabia',
        label: 'Saudi Arabia',
    },
    {
        value: 'Senegal',
        label: 'Senegal',
    },
    {
        value: 'Serbia',
        label: 'Serbia',
    },
    {
        value: 'Seychelles',
        label: 'Seychelles',
    },
    {
        value: 'Sierra Leone',
        label: 'Sierra Leone',
    },
    {
        value: 'Singapore',
        label: 'Singapore',
    },
    {
        value: 'Slovak Republic (Slovakia)',
        label: 'Slovak Republic (Slovakia)',
    },
    {
        value: 'Slovenia',
        label: 'Slovenia',
    },
    {
        value: 'Solomon Islands',
        label: 'Solomon Islands',
    },
    {
        value: 'Somalia',
        label: 'Somalia',
    },
    {
        value: 'South Africa',
        label: 'South Africa',
    },
    {
        value: 'South Sudan',
        label: 'South Sudan',
    },
    {
        value: 'Spain',
        label: 'Spain',
    },
    {
        value: 'Sri Lanka',
        label: 'Sri Lanka',
    },
    {
        value: 'Sudan',
        label: 'Sudan',
    },
    {
        value: 'Suriname',
        label: 'Suriname',
    },
    {
        value: 'Sweden',
        label: 'Sweden',
    },
    {
        value: 'Switzerland',
        label: 'Switzerland',
    },
    {
        value: 'Syria',
        label: 'Syria',
    },
    {
        value: 'Tajikistan',
        label: 'Tajikistan',
    },
    {
        value: 'Tanzania',
        label: 'Tanzania',
    },
    {
        value: 'Thailand',
        label: 'Thailand',
    },
    {
        value: 'Timor Leste',
        label: 'Timor Leste',
    },
    {
        value: 'Togo',
        label: 'Togo',
    },
    {
        value: 'Trinidad & Tobago',
        label: 'Trinidad & Tobago',
    },
    {
        value: 'Tunisia',
        label: 'Tunisia',
    },
    {
        value: 'Turkey',
        label: 'Turkey',
    },
    {
        value: 'Turkmenistan',
        label: 'Turkmenistan',
    },
    {
        value: 'Turks & Caicos Islands',
        label: 'Turks & Caicos Islands',
    },
    {
        value: 'Uganda',
        label: 'Uganda',
    },
    {
        value: 'Ukraine',
        label: 'Ukraine',
    },
    {
        value: 'United Arab Emirates',
        label: 'United Arab Emirates',
    },
    {
        value: 'United States of America (USA)',
        label: 'United States of America (USA)',
    },
    {
        value: 'Uruguay',
        label: 'Uruguay',
    },
    {
        value: 'Uzbekistan',
        label: 'Uzbekistan',
    },
    {
        value: 'Venezuela',
        label: 'Venezuela',
    },
    {
        value: 'Vietnam',
        label: 'Vietnam',
    },
    {
        value: 'Virgin Islands (UK)',
        label: 'Virgin Islands (UK)',
    },
    {
        value: 'Virgin Islands (US)',
        label: 'Virgin Islands (US)',
    },
    {
        value: 'Yemen',
        label: 'Yemen',
    },
    {
        value: 'Zambia',
        label: 'Zambia',
    },
    {
        value: 'Zimbabwe',
        label: 'Zimbabwe',
    },
];

const pref_times = [
    {
        value: 'None',
        label: 'Select your preffered time for conversation'
    },
    {
        value: 'Morning',
        label: 'Morning',
    },
    {
        value: 'Afternoon',
        label: 'Afternoon',
    },
    {
        value: 'Evening',
        label: 'Evening',
    },
]
export default function PersonalInfo (props) {
    const {
        formField: {
          full_name,
          email_address,
        //   phone_num,
          country,
        //   postal_code,
        //   pref_time,
        }
      } = props;
      
    return ( 
        <React.Fragment>
            <Row className="m-0">
                <Col xs={12} md={6}>
                    <InputField id="full_name" name={full_name.name} placeholder={full_name.label}/>
                </Col>
                <Col xs={12} md={6}>
                    <InputField id="email_address" name={email_address.name} placeholder={email_address.label}/>
                </Col>
            </Row>
            <Row className="m-0">
                {/* <Col xs={12} md={6}>
                    <PhoneDropdown id="phone_num" name={phone_num.name}/>
                </Col> */}
                <Col xs={12} md={6}>
                    <SelectField id="country" name={country.name} label={country.label} data={countries} />
                </Col>
            </Row>
            {/* <Row className="m-0">
                <Col xs={12} md={6}>
                    <InputField id="postal_code" name={postal_code.name} placeholder="Postal Code"/>
                </Col>
                <Col xs={12} md={6}>
                    <SelectField id="pref_time" name={pref_time.name} label={pref_time.label} data={pref_times}/>
                </Col>
            </Row> */}
        </React.Fragment>
    );
}