import React from 'react';
import { at } from 'lodash';
import { useField, ErrorMessage } from 'formik';

export default function InputField (props) {
    const { errorText, type, ...rest } = props;
    const [field, meta] = useField(props);
    
    function _renderHelperText() {
      const [touched, error] = at(meta, 'touched', 'error');
      if (touched && error) {
        return error;
      }
    }
  
    return (
      <React.Fragment>
        <input
          className="form-control mt-4"
          type={type ? type : "text"}
          error={!meta.isValid && meta.touched && meta.error && true}
          helperText={_renderHelperText()}
          {...field}
          {...rest}
        />
        <p style={{color: "#ff0000"}}>{_renderHelperText()}</p>
        {/* <ErrorMessage name={field.name} /> */}
      </React.Fragment>
    );
  }