import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Header from "../components/header.jsx";
import { Tabs, Tab } from "react-bootstrap";
import ContactUsForm from "../components/contactUsForm/contactUsForm.jsx";
import { ToastContainer, toast } from "react-toastify";
import Globe from "../components/Globe/Globe.js";

const ContactUs = (props) => {
  const { location } = props.location.pathname;
  const [currentLocation, setCurrentLocation] = useState("pakistan");
  const [coordinates, setCoordinates] = useState([24.87847528189816, 67.06317764085235]);
  const [currentAddress, setCurrentAddress] = useState(
    "https://www.google.com/maps/place/Digital+Landscape+(Pvt.)+Ltd./@24.878261,67.06314,21z/data=!4m12!1m6!3m5!1s0x3eb33fef1b5e1c7f:0x7bb3d3b9ea276cb4!2sDigital+Landscape+(Pvt.)+Ltd.!8m2!3d24.8782614!4d67.0631396!3m4!1s0x3eb33fef1b5e1c7f:0x7bb3d3b9ea276cb4!8m2!3d24.8782614!4d67.0631396"
  );

  const handleSelect = (e) => {
    // alert(e)
    if (e === "pakistan") {
      setCoordinates([24.87847528189816, 67.06317764085235])
    }
    else if (e === "usa") {
      setCoordinates([34.10290092181953, -118.13175424725131])
    }
    else {
      setCoordinates([51.525784381797365, -0.08363027352727587])
    }
    setCurrentLocation(e);
  };
  const GetGoogleMaps = () => {
    // If we haven't already defined the promise, define it
    const GoogleMapsPromise = new Promise((resolve) => {
      // Add a global handler for when the API finishes loading
      window.resolveGoogleMapsPromise = () => {
        const google = window.google;
        // Resolve the promise
        resolve(google);

        // Tidy up
        delete window.resolveGoogleMapsPromise;
      };

      // Load the Google Maps API
      const script = document.createElement("script");
      const API = "AIzaSyDUeEGbK_qS8PVRNw2z4wyjaNj9iNDEGWw";
      script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&callback=resolveGoogleMapsPromise`;
      script.async = true;
      document.body.appendChild(script);
    });

    // Return a promise for the Google Maps API
    return GoogleMapsPromise;
  };

  const copyAddress = () => {
    var address = document.getElementById("address");
    address.select();
    address.setSelectionRange(0, 99999);
    document.execCommand("copy");
    toast.success("Address copied!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    // notify();
  };

  useEffect(() => {
    // Once the Google Maps API has finished loading, initialize the map
    GetGoogleMaps().then((google) => {
      let uluru, zoom;
      // if (currentLocation === "pakistan") {
      //   uluru = { lat: 24.878261, lng: 67.06314 };
      //   zoom = 19;
      //   setCurrentAddress(
      //     "https://www.google.com/maps/place/Digital+Landscape+(Pvt.)+Ltd./@24.878261,67.06314,21z/data=!4m12!1m6!3m5!1s0x3eb33fef1b5e1c7f:0x7bb3d3b9ea276cb4!2sDigital+Landscape+(Pvt.)+Ltd.!8m2!3d24.8782614!4d67.0631396!3m4!1s0x3eb33fef1b5e1c7f:0x7bb3d3b9ea276cb4!8m2!3d24.8782614!4d67.0631396"
      //   );
      // } else 
      if (currentLocation === "uk") {

        uluru = { lat: 51.525784381797365, lng: -0.08363027352727587 };
        zoom = 15;
        setCurrentAddress(
          "https://www.google.com/maps/place/86-90+Paul+St,+London+EC2A+4NE,+UK/@51.5256275,-0.0858297,17z/data=!3m1!4b1!4m6!3m5!1s0x48761ca5605ad9fd:0x455c57df1d76f556!8m2!3d51.5256242!4d-0.083641!16s%2Fg%2F11gxnlq228"
        );
      } else {
        uluru = { lat: 34.10290092181953, lng: -118.13175424725131 };
        zoom = 19;
        setCurrentAddress(
          "https://www.google.com/maps/place/808+N+Garfield+Ave,+Alhambra,+CA+91801,+USA/@34.1027632,-118.1338893,17z/data=!3m1!4b1!4m6!3m5!1s0x80c2c4dc43620805:0x9fe5eaa3c917294c!8m2!3d34.1027588!4d-118.1317006!16s%2Fg%2F11k71rl3y_"
        );
      }

      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: zoom,
        center: uluru,
      });
      const marker = new google.maps.Marker({
        position: uluru,
        map: map,
      });
    });
  }, [currentLocation]);

  return (
    <React.Fragment>

      <Header locationName={location} />
      <div className="contact-banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 d-flex flex-wrap justify-content-between align-items-center">
              <div className="col-lg-5">
                <div className="banner-content w-100">
                  <h3>Contact Us</h3>
                  <h6>Integrating Communities</h6>
                  <p>
                    "Providing the simplest solution for the most complex
                    problems"
                  </p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="banner-img-area w-100">
                  <span>
                    <img
                      className="img1"
                      src="/media/paper-animate1.png"
                      alt=""
                    />
                  </span>
                  <span>
                    <img
                      className="img2"
                      src="/media/paper-animate2.png"
                      alt=""
                    />
                  </span>
                  <span>
                    <img
                      className="img2"
                      src="/media/paper-animate3.png"
                      alt=""
                    />
                  </span>
                  <span>
                    <img
                      className="img2"
                      src="/media/paper-animate4.png"
                      alt=""
                    />
                  </span>
                  <span>
                    <img
                      className="img2"
                      src="/media/paper-animate5.png"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section id="contact-us-sec">
      </section> */}
      <div className="mt-0">
        <Globe coordinates={coordinates} />
      </div>
      <section id="contact-us-sec">
        <Tabs
          defaultActiveKey="uk"
          id="uncontrolled-tab-example"
          className="mt-3 mb-3"
          onSelect={(event) => handleSelect(event)}
        >
          
          <Tab eventKey="uk" title="UK">
            <div className="container my-5 contact-div">
              <Row className="text-center d-flex justify-content-center">
                <Col
                  xs={10}
                  lg={3}
                  className=" d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon8.svg" alt="" className="my-1" />
                    </p>
                    <h4 className="contact-num">Shoreditch Tech</h4>
                    <br />
                    {/* <span>(President North America Region)</span> */}
                  </div>
                </Col>
                <Col
                  xs={10}
                  lg={3}
                  className=" d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon5.svg" alt="" className="my-1" />
                    </p>
                    <h4 className="contact-num">+44 7393 815444</h4>
                    {/* <span></span> */}
                  </div>
                </Col>
                <Col
                  xs={10}
                  lg={3}
                  className="d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon6.svg" alt="" className="my-1" />
                    </p>
                    <h4 className="location">
                      86-90, Paul Street, London EC2A 4NE. United Kingdom
                    </h4>
                  </div>
                </Col>
                <Col
                  xs={10}
                  lg={3}
                  className="d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon7.svg" alt="" className="mt-2" />
                    </p>
                    <h4 className="email-address">
                      <a
                        href="mailto:feedback@dls-global.com"
                        style={{ color: "rgb(96 96 96)" }}
                      >
                        feedback@dls-global.com
                      </a>
                    </h4>
                  </div>
                </Col>
              </Row>
            </div>
          </Tab>
          <Tab eventKey="usa" title="USA">
            <div className="container my-5 contact-div">
              <Row className="text-center d-flex justify-content-center">
                <Col
                  xs={10}
                  lg={3}
                  className=" d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon8.svg" alt="" className="my-1" />
                    </p>
                    <h4 className="contact-num">Blaylock Engineering</h4>
                    {/* <span>President North America Region</span> */}
                  </div>
                </Col>
                <Col
                  xs={10}
                  lg={3}
                  className=" d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon5.svg" alt="" className="my-1" />
                    </p>
                    <h4 className="contact-num">+ (213) 600-7662</h4>
                    {/* <span></span> */}
                  </div>
                </Col>
                <Col
                  xs={10}
                  lg={3}
                  className="d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon6.svg" alt="" className="my-1" />
                    </p>
                    <h4 className="location"> 808 N Garfield Ave Unit A, Alhambra, CA 91801</h4>
                    {/* <span>
                        
                    </span> */}
                  </div>
                </Col>
                <Col
                  xs={10}
                  lg={3}
                  className="d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon7.svg" alt="" className="mt-2" />
                    </p>
                    <h4 className="email-address">
                      <a
                        href="mailto:feedback@dls-global.com"
                        style={{ color: "rgb(96 96 96)" }}
                      >
                        feedback@dls-global.com
                      </a>
                    </h4>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="container my-5 contact-div">
              <Row className="text-center d-flex justify-content-center">
                <Col
                  xs={10}
                  lg={3}
                  className=" d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon8.svg" alt="" className="my-1" />
                    </p>
                    <h4 className="contact-num">DLS-Global</h4>
                    {/* <span>President North America Region</span> */}
                  </div>
                </Col>
                <Col
                  xs={10}
                  lg={3}
                  className=" d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon5.svg" alt="" className="my-1" />
                    </p>
                    <h4 className="contact-num">+ (213) 600-7662</h4>
                    {/* <span></span> */}
                  </div>
                </Col>
                <Col
                  xs={10}
                  lg={3}
                  className="d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon6.svg" alt="" className="my-1" />
                    </p>
                    <h4 className="location"> Suite No: 808 N Garfield Ave Unit A, Alhambra, CA 91801LA</h4>
                    {/* <span>
                        
                    </span> */}
                  </div>
                </Col>
                <Col
                  xs={10}
                  lg={3}
                  className="d-flex flex-column justify-content-center align-items-center my-3"
                >
                  <div className="contact-details">
                    <p>
                      <img src="/media/icon7.svg" alt="" className="mt-2" />
                    </p>
                    <h4 className="email-address">
                      <a
                        href="mailto:feedback@dls-global.com"
                        style={{ color: "rgb(96 96 96)" }}
                      >
                        feedback@dls-global.com
                      </a>
                    </h4>
                  </div>
                </Col>
              </Row>
            </div>
          </Tab>
        </Tabs>
        <div className="container mb-5">
          <Row className="m-0">
            <Col lg={6} className="px-0">
              <div className="contact-form-area px-0">
                <div className="contact-us-form p-5">
                  <div>
                    <ContactUsForm currentLocation={currentLocation}/>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="px-0 map-img">
              <div id="map" className="w-100 h-100"></div>
              <div className="copyUrl">
                <button onClick={copyAddress}>Copy Address</button>
                <input id="address" type="text" value={currentAddress}></input>
                <ToastContainer />
              </div>
              {/* <Link to="https://www.google.com/maps/place/Digital+Landscape+(Pvt.)+Ltd./@24.878261,67.06314,21z/data=!4m12!1m6!3m5!1s0x3eb33fef1b5e1c7f:0x7bb3d3b9ea276cb4!2sDigital+Landscape+(Pvt.)+Ltd.!8m2!3d24.8782614!4d67.0631396!3m4!1s0x3eb33fef1b5e1c7f:0x7bb3d3b9ea276cb4!8m2!3d24.8782614!4d67.0631396"> */}
              {/* <img src="/media/map.png" alt="" className="w-100"/> */}
              {/* </Link> */}
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactUs;
