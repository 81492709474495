import React from "react";
import { Row, Col } from "react-bootstrap";
import InputField from './../formFields/inputField';
import SelectField from "../formFields/selectField"; 
import TextAreaField from "../formFields/textAreaField.jsx";

const designations = [
  {
    value: 'None',
    label: 'Your Designation'
  },
  {
    value: 'Owner',
    label: 'Owner'
  },
  {
    value: 'Employee',
    label: 'Employee'
  },
];

const customer_vendors = [
  {
    value: 'None',
    label: 'Are you customer or vendor?'
  },
  {
    value: 'Customer',
    label: 'Customer'
  },
  {
    value: 'Vendor',
    label: 'Vendor'
  },
]
export default function CompanyProfile (props) {
  const {
    formField: {
      company
      // company_name,
      // company_url,
      // business_domain,
      // designation,
      // customer_vendor,
    }
  } = props;
  return (
    <React.Fragment>
      {/* <Row className="m-0">
        <Col xs={12} md={6}>
          <InputField id="company_name" name={company_name.name} placeholder={company_name.label}/>
        </Col>
        <Col xs={12} md={6}>
          <InputField id="company_url" name={company_url.name} placeholder={company_url.label}/>
        </Col>
      </Row>
      <Row className="m-0">
        <Col xs={12} md={6}>
          <InputField id="business_domain" name={business_domain.name} placeholder={business_domain.label}/>
        </Col>
        <Col xs={12} md={6}>
          <SelectField id="designation" name={designation.name} label={designation.label} data={designations}/>
        </Col>
      </Row>
      <Row className="m-0">
        <Col xs={12}>
          <SelectField id="customer_vendor" name={customer_vendor.name} label={customer_vendor.label} data={customer_vendors}/>
        </Col>
      </Row> */}
      <Row className="m-0">
        <Col xs={12}>
          <TextAreaField id="company" name={company.name} placeholder={company.label}/>
        </Col>
      </Row>
    </React.Fragment>
  );
};
