import * as Yup from "yup";
import formModel from "./formModel.js";

const {
  formField: {
    full_name,
    email_address,
    phone_num,
    country,
    postal_code,
    pref_time,
    // company_name,
    // company_url,
    // business_domain,
    // designation,
    // customer_vendor,
    // start_project,
    // complete_project,
    // project_type,
    // language_required,
    no_of_pages,
    // reference_url,
    // price_range,
    // design_wireframe,
    // frontend,
    // backend,
    // required_db,
    // database_type,
    // project_desc,
    // uploaded_file
  },
} = formModel;

export default [
  Yup.object().shape({
    [full_name.name]: Yup.string()
      .required("This is a required field")
      .matches(/^[aA-zZ\s]+$/, "Invalid! Only alphabets are allowed"),
    [email_address.name]: Yup.string()
      .required("This is a required field")
      .email("Please enter a valid email address"),
  }),
];
