import React from "react";
import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, label }) => {
    return (
        <button
        type="link"
            to='#'
            onClick={(e) => {
                window.location = mailto;
                e.preventDefault();
            }}
            className="apply-btn"
        >
            {label}
        </button>
    );
};

export default ButtonMailto;