import formModel from "./formModel.js";

const {
  formField: {
    full_name,
    email_address,
    // phone_num,
    country,
    company,
    project_desc,
    // postal_code,
    // pref_time,
    // company_name,
    // company_url,
    // business_domain,
    // designation,
    // customer_vendor,
    // start_project,
    // complete_project,
    // project_type,
    // language_required,
    // no_of_pages,
    // reference_url,
    // price_range,
    // design_wireframe,
    // frontend,
    // backend,
    // required_db,
    // database_type,
    // project_desc,
    // uploaded_file
  },
} = formModel;

export default {
  [full_name.name]: "",
  [email_address.name]: "",
  // [phone_num.name]: '',
  [country.name]: "",
  [company.name]: "",
  // [postal_code.name]: '',
  // [pref_time.name]: '',
  // [company_name.name]: '',
  // [company_url.name]: '',
  // [business_domain.name]: '',
  // [designation.name]: '',
  // [customer_vendor.name]: '',
  // [start_project.name]: '',
  // [complete_project.name]: '',
  // [project_type.name]: '',
  // [language_required.name]: '',
  // [no_of_pages.name]: '',
  // [reference_url.name]: '',
  // [price_range.name]: '',
  // [design_wireframe.name]: '',
  // [frontend.name]: '',
  // [backend.name]: '',
  // [required_db.name]: '',
  // [database_type.name]: '',
  [project_desc.name]: '',
  // [uploaded_file.name]: '',
};
