import { React, Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

class Products extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    var settings = {
      dots: false,
      arrows: false,
      initialSlide: 1,
      slidesToShow: 3,
      slidesToScroll: 3,
      swipeToSlide: false,
      // autoplay: true,
      autoplay: false,
      // autoplaySpeed: 2000,
      infinite: true,
      cssEase: "linear",
      pauseOnHover: true,
      centerMode: true,
      centerPadding: "0px",
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            // speed: 5000,
            // autoplaySpeed: 5000,
          },
        },
        // {
        //   breakpoint: 991,
        //   settings: {
        //     slidesToShow: 2,
        //     speed: 5000,
        //     autoplaySpeed: 5000,
        //   }
        // },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            centerMode: true,
            autoplay: false,
            // autoplaySpeed: 5000,
          },
        },
      ],
    };
    const ProductsList = [
     
      {
        productId: 15,
        productBg: "/media/products/loreal/loreal-bg.png",
        productLogo: "/media/products/loreal/logo.png",
        productTitle:
          "The Trade Management System is a user-friendly web app that simplifies budget management, BSR generation, and claim settlements. It streamlines financial operations, ensuring transparency and efficient financial management.",
        productName: "L'oreal SYNC",
      },
      {
        productId: 16,
        productBg: "/media/products/iac/iac-bg.png",
        productLogo: "/media/products/iac/logo.png",
        productTitle:
          "Maximize salesforce effectiveness and monitor sales/distribution in FMCGs and other industries.",
        productName: "S&D IAC",
      },
      {
        productId: 2,
        productBg: "/media/products/pharma-track/pharmatrack-bg-img.webp",
        productLogo: "/media/products/pharma-track/logo.webp",
        productTitle:
          "A web-based system and mobile-based application designed for pharmaceutical companies to track sales on a real-time basis, along with the performance and real time engagement of medical sales representatives. The application features active dashboards, audio-video conferencing, doctors/representative ratings, team/tasks management, performance metrics, real-time communication, tracking, alerts, chats, graphs, meeting follows ups and more.",
        productName: "PharmaTrack",
      },
      {
        productId: 1,
        productBg: "/media/products/pap/pap-bg-img.webp",
        productLogo: "/media/products/pap/logo.webp",
        productTitle:
          "Patient Access Program is designed to facilitate patients from marginalized communities who are unable to afford the full price of the medicines they need.",
        productName: "Patient Access Program",
      },

      {
        productId: 3,
        productBg: "/media/products/meetup/meetup-bg-img.webp",
        productLogo: "/media/products/meetup/logo.webp",
        productTitle:
          "A cloud-based peer-to-peer communication software platform that allows users to create virtual spaces that powers all of your communication needs, including meetings, chat, phone, webinars, and online events. It has screen sharing and document sharing among other capabilities and can be a great tool for distance learning. Our application gallery allows users to experience features of all their favourite applications.",
        productName: "MeetUp",
      },
      {
        productId: 5,
        productBg:
          "/media/products/education-portal/education-portal-bg-img.webp",
        productLogo: "/media/products/education-portal/logo.webp",
        productTitle:
          "This is a web and mobile-based management system for schools, colleges, and universities.",
        productName: "Education Portal",
      },
      {
        productId: 4,
        productBg: "/media/products/healthe/healthe-bg-img.webp",
        productLogo: "/media/products/healthe/logo.webp",
        productTitle:
          "A state of the art health care mobile application covering patients, doctors, pharmaceuticals, labs, hospitals, first responders and more.",
        productName: "HealthE",
      },

      // {
      //   productId: 6,
      //   productBg: "/media/products/smart-s&d/smart-snd-bg-img.webp",
      //   productLogo: "/media/products/smart-s&d/logo.webp",
      //   productTitle:
      //     "S&D helps businessed control and monitor their sales and distribution process through a simple mobile application.",
      //   productName: "Smart S&D",
      // },
      {
        productId: 8,
        productBg: "/media/products/vits/vits-bg-img.webp",
        productLogo: "/media/products/vits/logo.webp",
        productTitle:
          "Vits is an Online Portal to fulfill the need of Human Resource Management of companies.",
        productName: "Vits - HRMS",
      },
      // {
      //   productId: 7,
      //   productBg: "/media/products/pharma-s&d/pharma-snd-bg-img.webp",
      //   productLogo: "/media/products/pharma-s&d/logo.webp",
      //   productTitle:
      //     "A mobile-based tracking application specifically designed for pharmaceutical companies to track, control and monitor sales and distribution of their medicines. The application also allows performance tracking of their on-ground sales personnel and maintain salesforce effectiveness across business functions to help maximize productivity and profitability.",
      //   productName: "Smart S&D Pharma",
      // },

      {
        productId: 9,
        productBg: "/media/products/sales&predict/sales-predict-bg-img.webp",
        productLogo: "/media/products/sales&predict/logo.webp",
        productTitle:
          "This system covers the scope to predict future sales and provide recommendations for business development.",
        productName: "Sales & Predict",
      },
      {
        productId: 10,
        productBg:
          "/media/products/cancer-classification/cancer-detect-bg-img.webp",
        productLogo: "/media/products/cancer-classification/logo.webp",
        productTitle:
          "The Cancer Classification is a web-based AI system that detects 7 different types of skin cancer. It takes dermatoscopic images as an input to identify the type of skin cancer, with an accuracy rate of 97%.",
        productName: "Cancer Classification System",
      },
      {
        productId: 11,
        productBg:
          "/media/products/crime-detection/crime-detection-bg-img.webp",
        productLogo: "/media/products/crime-detection/logo.webp",
        productTitle:
          "A web-based product designed to detect criminal activity, with the help of Artificial Intelligence.",
        productName: "Crime Detection System",
      },
      {
        productId: 12,
        productBg:
          "/media/products/face-recognition/facial-expression-bg-img.webp",
        productLogo: "/media/products/face-recognition/logo.webp",
        productTitle:
          "This product detects faces using Artificial Intelligence and recognizes facial expressions.",
        productName: "Facial Recognition System",
      },
      {
        productId: 13,
        productBg:
          "/media/products/facial-expression-recognition/facial-recog-bg-img.webp",
        productLogo: "/media/products/facial-expression-recognition/logo.webp",
        productTitle:
          "A web-based system which is able to detect face and then recognize it's expressions.",
        productName: "Facial Expression Recognition System",
      },
      {
        productId: 14,
        productBg: "/media/products/sign-language/sign-language-bg-img.webp",
        productLogo: "/media/products/sign-language/logo.webp",
        productTitle:
          "The scope of this project is to detect signs of American Sign Language (ASL).",
        productName: "Sign Language Detection System",
      },
    ];
    return (
      <section id="our-products-sec">
        {/* <img className="img-bg"  src="/media/products-bg.png" alt=""/> */}
        <Row className="product-sec-desc m-0">
          <Col xs={12}>
            <h4 className="title-yellow-bar ml-5">What we offer</h4>
            <p className="overview-white ml-5">
              Exciting web and mobile applications for various business models.
              Digitize your business today and start growing!
            </p>
          </Col>
          <Col xs={12}>
            <div className="slick-buttons">
              <button onClick={this.previous}>
                <svg
                  width="40px"
                  height="50px"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="angle-left"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 256 512"
                  className="svg-inline--fa fa-angle-left fa-w-8 fa-2x"
                >
                  <path
                    fill="#ffffff"
                    d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                    className=""
                  ></path>
                </svg>
              </button>
              <button onClick={this.next}>
                <svg
                  width="40px"
                  height="50px"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="angle-right"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 256 512"
                  className="svg-inline--fa fa-angle-right fa-w-8 fa-2x"
                >
                  <path
                    fill="#ffffff"
                    d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                    className=""
                  ></path>
                </svg>
              </button>
            </div>
          </Col>
        </Row>
        <Slider ref={(c) => (this.slider = c)} {...settings}>
          {ProductsList.map((product) => (
            <div key={product.productId}>
              <div>
                <Link to={"/product-details/" + product.productId}>
                  <div className="product">
                    <LazyLoadImage
                      alt="product-alt"
                      src={product.productBg}
                      effect="black-and-white"
                    />
                    {/* <img src={product.productBg} alt="" className="product-bg" /> */}
                    <p className="product-desc">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        className="bi bi-arrow-up-right-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8zm5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707l-4.096 4.096z" />
                      </svg>{" "}
                      <br />
                      {product.productTitle}
                    </p>
                  </div>
                  <div className="product-content">
                    <img
                      src={product.productLogo}
                      alt=""
                      className="product-logo"
                    />
                    <h4 className="product-name">
                      {product.productName}
                      <sup className="ml-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#4488d2"
                          class="bi bi-badge-tm"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.295 11V5.995H7V5H2.403v.994h1.701V11h1.19zm3.397 0V7.01h.058l1.428 3.239h.773l1.42-3.24h.057V11H13.5V5.001h-1.2l-1.71 3.894h-.039l-1.71-3.894H7.634V11h1.06z"></path>
                          <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"></path>
                        </svg>
                      </sup>
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </section>
    );
  }
}

export default Products;
