const JobsData = [
  {
    title: "Content Writer",
    desc: "We are looking for a Content Writer to join our team. A person responsible for creating print and digital content that provides information or showcases the company’s products and services.",
    professionalSkills: [
      "Impeccable grasp of the English language, understanding grammar rules and how to construct clear sentences.",
      "Organize writing schedules to complete drafts of content or finished projects within deadlines.",
      "Utilize industry best practices and familiarity with the organization's mission to inspire ideas and content.",
      "Develop related content for multiple platforms, such as websites, email marketing, product descriptions, videos, and blogs.",
      "Monitor and analyze the performance of key performance indicators (KPIs) to offer suggestions for improvement.",
      "Use search engine optimization (SEO) strategies in writing to maximize the online visibility of a website in search results.",
      "Proficiency with computers, especially writing programs, such as Google Docs and Microsoft Word, Excel, Outlook, and PowerPoint.",
    ],
    personalSkills: [
      "Ability to work on multiple projects with different objectives simultaneously.",
      "A strong attention to detail and the ability to work under pressure is essential.",
      "Ability to work independently with little or no daily supervision.",
      "Good time management skills, including prioritizing, scheduling, and adapting as necessary.",
      "Strong interpersonal skills and willingness to communicate with clients, colleagues, and management.",
    ],
    responsibilities: [
      "Prepare document outlines, updating content based on client edit requests and proofreading their work for tone, style, clarity, grammar and formatting.",
      "Completing writing projects, meeting deadlines and following content requirements in terms of style and project specifications.",
      "Utilize industry best practices and familiarity with the organization's mission to inspire ideas and content.",
      "Conducting research on any given topic.",
    ],
    qualification: "Must be enrolled in any bachelor's degree",
    details: {
      industry: "Software/Information Technology",
      position: "1",
      shift: "Morning",
      type: "Full Time",
      location: "Karachi",
      gender: "N/A",
      age: "20+",
    },
  },
  {
    title: "Graphic Designer",
    desc: "We are looking for a Graphic Designer to create engaging and on-brand graphics for a web, applications and other projects.",
    professionalSkills: [
      "Must known the process of defining requirements, visualizing and creating graphics including illustrations, logos, layouts and photos.",
      "Able to shape the visual aspects of websites, books, magazines, product packaging, exhibitions and more.",
      "Your graphics should capture the attention of those who see them and communicate the right message. ",
      "Must be creative flair and a strong ability to translate requirements into design.",
    ],
    personalSkills: [
      "Ability to work on multiple projects with different objectives simultaneously.",
      "A strong attention to detail and the ability to work under pressure is essential.",
      "Ability to work independently with little or no daily supervision.",
      "Good time management skills, including prioritizing, scheduling, and adapting as necessary.",
      "Strong interpersonal skills and willingness to communicate with clients, colleagues, and management.",
    ],
    responsibilities: [
      "Study design briefs and determine requirements.",
      "Schedule projects and define budget constraints.",
      "Conceptualize visuals based on requirements.",
      "Prepare rough drafts and present ideas.",
      "Develop illustrations, logos and other designs using software or by hand.",
      "Use the appropriate colors and layouts for each graphic.",
      "Work with copywriters and creative director to produce final design.",
      "Test graphics across various media.",
      "Amend designs after feedback.",
      "Ensure final graphics and layouts are visually appealing and on-brand.",
    ],
    qualification: "Must be enrolled in any bachelor's degree or equivelent",
    details: {
      industry: "Software/Information Technology",
      position: "1",
      shift: "N/A",
      type: "Full/Part time",
      location: "Karachi",
      gender: "N/A",
      age: "20+",
      deadline: "N/A",
    },
  },
];
export default JobsData;
