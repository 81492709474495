import {React, Component} from "react";
import {Row, Col} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Team extends Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
    this.slider.slickNext();
    }
    previous() {
    this.slider.slickPrev();
    }
    render(){
        var settings = {
            slidesToScroll: 1,
            slidesToShow:3,
            autoplay: true,
            autoplaySpeed: 2000,
            centerMode: false,
            // centerPadding: '25px',
            dots: false,
            arrows: false,
            initialSlide:1,
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 1,
                  }
                }
            ]
        };
        return ( 
            <section id="our-team-sec">
                <div className="container-fluid">
                    <Row className="row m-0">
                        <Col xs={12}>
                            <h4 className="title-blue-bar ml-5 mt-3 mb-4">Our Team</h4>
                            <p className="overview-black ml-5">Meet our biggest assets!</p>
                        </Col>
                        <Col xs={12}>
                            <div className="slick-buttons">
                                <button onClick={this.previous}>
                                    <svg width="40px" height="50px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="svg-inline--fa fa-angle-left fa-w-8 fa-2x"><path fill="#ffffff" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" className=""></path></svg>
                                </button>
                                <button onClick={this.next}>
                                    <svg width="40px" height="50px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" className="svg-inline--fa fa-angle-right fa-w-8 fa-2x"><path fill="#ffffff" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" className=""></path></svg>
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                        <div>
                            <div className="member">
                                <div className="light-blue-bg member-quote-blue">
                                    <img src="/media/team/member1.webp" alt=""/>
                                    <p className="m-0">"A website without visitors is like a ship lost in the horizon."</p>
                                </div>
                                <h5 className="member-name my-3">Ali Abbas</h5>
                                <h5 className="member-position member-position-blue my-3">Sr. Software Engineer</h5>
                                {/* <h5 className="member-qualification my-3">BSCS (Iqra University)</h5> */}
                            </div>
                        </div>
                        <div>
                            <div className="member">
                                <div className="light-blue-bg member-quote-blue">
                                    <img src="/media/team/member4.webp" alt="" className="member-img"/>
                                    <p className="m-0">"We will either find a way, or make one."</p>
                                </div>
                                <h5 className="member-name my-3">Fahad Khan</h5>
                                <h5 className="member-position member-position-blue my-3">Project Manager</h5>
                                {/* <h5 className="member-qualification my-3">BSCS (University of Karachi)</h5> */}
                            </div>
                        </div>
                        <div>
                            <div className="member">
                                <div className="dark-blue-bg member-quote-dark-blue">
                                    <img src="/media/team/member5.webp" alt="" className="member-img"/>
                                    <p className="m-0">"Tech will transform from something we actively use to a more seamless integrated experience that is on all the time."</p>
                                </div>
                                <h5 className="member-name my-3">Bilal Khan</h5>
                                <h5 className="member-position member-position-dark-blue my-3">Sr. Software Engineer</h5>
                                {/* <h5 className="member-qualification my-3">MCS (University of Sindh)</h5> */}
                            </div>
                        </div>
                        <div>
                            <div className="member">
                                <div className="dark-blue-bg member-quote-dark-blue">
                                    <img src="/media/team/member8.webp" alt="" className="member-img"/>
                                    <p className="m-0">"Our insight is the thing that makes us more intelligent, and AI is an augmentation of that quality."</p>
                                </div>
                                <h5 className="member-name my-3">Maaz Rafiq</h5>
                                <h5 className="member-position member-position-dark-blue my-3">Software Engineer</h5>
                                {/* <h5 className="member-qualification my-3">BSSE (MAJU)</h5> */}
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        );
    }
}
 
export default Team;