export default {
  formId: "rfqForm",
  formField: {
    full_name: {
      name: "full_name",
      label: "Full Name*",
    },
    email_address: {
      name: "email_address",
      label: "Email Address*",
    },
    // phone_num: {
    //     name: 'phone_num',
    //     label: 'Phone number',
    // },
    country: {
      name: "country",
      label: "Select your country",
    },
    company: {
      name: "company",
      label: "",
    },
    project_desc: {
      name: "project_desc",
      label: "e.g. Technical Requirements",
    },
    // postal_code: {
    //     name: 'postal_code',
    //     label: 'Postal code',
    // },
    // pref_time: {
    //     name: 'pref_time',
    //     label: 'Select your preffered time for conversation',
    // },
    // company_name: {
    //     name: 'company_name',
    //     label: 'Your company name',
    // },
    // company_url: {
    //     name: 'company_url',
    //     label: 'Company URL',
    // },
    // business_domain: {
    //     name: 'business_domain',
    //     label: 'Business domain',
    // },
    // designation: {
    //     name: 'designation',
    //     label: 'Your designation',
    // },
    // customer_vendor: {
    //     name: 'customer_vendor',
    //     label: 'Are you Customer or Vendor?',
    // },
    // start_project: {
    //     name: 'start_project',
    //     label: 'How soon you are ready to start?',
    // },
    // complete_project: {
    //     name: 'complete_project',
    //     label: 'Select expected completion timeline',
    // },
    // project_type: {
    //     name: 'project_type',
    //     label: 'Select your project type',
    // },
    // language_required: {
    //     name: 'language_required',
    //     label: 'Language required for project',
    // },
    // no_of_pages: {
    //     name: 'no_of_pages',
    //     label: 'Number of web/application screens',
    // },
    // design_wireframe: {
    //     name: 'design_wireframe',
    //     label: 'Do you have a design or wireframe?',
    // },
    // price_range: {
    //     name: 'price_range',
    //     label: 'Select your expected price range',
    // },
    // reference_url: {
    //     name: 'reference_url',
    //     label: 'Reference URL',
    // },
    // frontend: {
    //     name: 'frontend',
    //     label: 'Frontend Technology',
    // },
    // backend: {
    //     name: 'backend',
    //     label: 'Backend Technology',
    // },
    // required_db: {
    //     name: 'required_db',
    //     label: 'Database Required?',
    // },
    // database_type: {
    //     name: 'database_type',
    //     label: 'Database Type? (if required)',
    // },
    // project_desc: {
    //     name: 'project_desc',
    //     label: 'Project description'
    // },
    // uploaded_file: {
    //     name: 'uploaded_file',
    //     label: 'Attach a file for futher details (if any)'
    // }
  },
};
