// import { indexOf } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import BIRDS from 'vanta/dist/vanta.net.min.js';
import * as THREE from 'three';
import {Row, Col} from "react-bootstrap";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Technologies = () => {
    const [vantaEffect, setVantaEffect] = useState(0)
    const myRef = useRef(null)
    // var keyCount = 0;
    const technologiesList1 = [
        "/media/technologies/1.svg",
        "/media/technologies/2.svg",
        "/media/technologies/3.svg",
        "/media/technologies/4.svg",
        "/media/technologies/5.svg",
        "/media/technologies/6.svg",
        "/media/technologies/7.svg",
        "/media/technologies/8.svg",
        "/media/technologies/9.svg",
        "/media/technologies/10.svg",
        "/media/technologies/11.svg",
        "/media/technologies/12.svg",
        "/media/technologies/13.svg",
        "/media/technologies/14.svg",
        "/media/technologies/15.svg",
        "/media/technologies/16.svg",
        "/media/technologies/17.svg",
        "/media/technologies/18.svg",
        "/media/technologies/19.svg",
        "/media/technologies/20.svg",
        "/media/technologies/21.svg",
        "/media/technologies/22.svg",
    ];
    const technologiesList2 = [
        "/media/technologies/23.svg",
        "/media/technologies/24.svg",
        "/media/technologies/25.svg",
        "/media/technologies/26.svg",
        "/media/technologies/27.svg",
        "/media/technologies/28.svg",
        "/media/technologies/29.svg",
        "/media/technologies/30.svg",
        "/media/technologies/31.svg",
        "/media/technologies/32.svg",
        "/media/technologies/33.svg",
        "/media/technologies/34.svg",
        "/media/technologies/35.svg",
        "/media/technologies/36.svg",
        "/media/technologies/37.svg",
        "/media/technologies/38.svg",
        "/media/technologies/39.svg",
        "/media/technologies/40.svg",
        "/media/technologies/41.svg",
        "/media/technologies/42.svg",
        "/media/technologies/43.svg",
        "/media/technologies/44.svg",
    ]

    useEffect(() => {
        if (!vantaEffect) {
          setVantaEffect(BIRDS({
            THREE,
            el: myRef.current,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: 0xffffff,
            backgroundColor: 0x1c3a85,
            points: 18.00,
            maxDistance: 25.00,
            spacing: 30.00
          }))
        }
        return () => {
          if (vantaEffect) vantaEffect.destroy()
        }
    }, [vantaEffect])

    return ( 

        <section id="technologies-sec" ref={myRef}>
            {/* <img className="img-bg" src="/media/tech-bg.png" alt="" /> */}
            <div className="container-fluid">
                <Row className="m-0">
                    <Col xs={12} lg={6} className="technology-content-block d-flex flex-column flex-wrap align-items-start justify-content-center pl-5 col-lg-6 col-12">
                        <h4 className="title-yellow-bar pt-5">Technology Stack</h4>
                        <p className="technologies-desc">Our expertise in latest development frameworks and technologies has enabled us to use a variety of suitable technology stack that businesses request.</p>
                    </Col>
                    <Col xs={12} lg={6} className="technology-list-block">
                        <Row className="m-0 justify-content-center">
                            <Col xs={5} className="px-2">
                                <ul className="list1 list-unstyled d-flex align-items-end flex-column pt-lg-5 mr-3">
                                    {technologiesList1.map((technology , index) => (
                                        <li key={index}>
                                            {/* <img src={technology} alt="" className="w-100 h-85"/> */}
                                            {/* <LazyLoadImage alt="technology-alt" src={technology} effect="black-and-white"/> */}
                                            <span className={"tech" + index}></span>    
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                            <Col xs={5} className="px-2">
                                <ul className="list2 list-unstyled">
                                    {technologiesList2.map((technology , index) => (
                                        <li key={index + 1}>
                                            {/* <img src={technology} alt="" className="w-100 h-85"/> */}
                                            {/* <LazyLoadImage alt="technology-alt" src={technology} effect="black-and-white"/> */}
                                            <span className={"tech" + index}></span> 
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </section>
    );
}
 
export default Technologies;