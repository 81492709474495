import React from 'react'
import Main from "../components/chatModule/Main"
import {useState} from 'react';



const chatbot = () => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {
    setIsShown(current => !current);
    
    handlingScroll();

  };

const enableScroll=()=>{
  document.body.style.overflow = 'unset';
  // document.ChatbotView.style.overflow="unset"
}
const disbaleScroll=()=>{
  if (typeof window != 'undefined' && window.document) {
    document.body.style.overflow = 'hidden';
    // document.ChatbotView.style.overflow="unset"
  }
}

function handlingScroll() {
    isShown?enableScroll(): disbaleScroll()
}
  
  return (
    <div className="chtabotMain">
      <button className="chatBotBtn" onClick={handleClick}>
      {/* <img src="/media/rotate-icon.svg" alt="" className="rotate-icon"/>  */}
            {/* <img src="/media/boss.png" className="boss-pic" alt="" /> */}
            {/* <img src="/media/boss3.png" className="bot-pic" alt="" /> */}
            <img src="/media/boss3.png" className="bot-pic" alt="" />
            {/* <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="d-none svg-inline--fa fa-times fa-w-10 fa-2x"><path fill="#3e7bc5" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" class=""></path></svg> */}

      {/* <img src="/media/boss3.png" className="bot-pic" alt="" /> */}
      </button>
      {/* {isShown && <Main className="ChatbotView" 
     />} */}
    <Main className="ChatbotView" isShown={isShown}/>
    </div>
    )
}

export default chatbot
