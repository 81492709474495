import React, {useState} from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import Header from "../components/header.jsx";
import Products from "../containers/our-products.jsx";
import { useParams } from "react-router-dom";
import ImageDialoge from "./imageDialog"

const ProductDetails = (props) => {
  const { id } = useParams();
  const ProductId = id - 1;
  var settings = {
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "0px",
    autoplaySpeed: 2000,
    dots: true,
    arrows: false,
  };
  const ProductsList = [
    {
      id: 1,
      name: "Patient Access Program",
      logo: "/media/products/pap/logo.webp",
      title:
        "Patient access program is a web-based system designed to facilitate patients from marginalized communities who are unable to afford the full price of the medicines they need.",
      webCover: "/media/products/pap/web-cover.webp",
      features: [
        "Gather patient's information through a registration process.",
        "Assess and record patient's income to determine the customized plan.",
        "Store and maintain medical records and medicine dosages.",
        "Dispensing practices are generated to ensure that the right medicines of desired quality are delivered correctly to the right patient with the right dose and quantity, together with clear instructions, both written and verbal.",
        "All reactions are recorded, including adverse events.",
        "After 12 months or desired time length, the plan expires and the renewal process is initiated.",
      ],
      screens: [
        "/media/products/pap/screen1.webp",
        "/media/products/pap/screen2.webp",
        "/media/products/pap/screen3.webp",
        "/media/products/pap/screen4.webp",
        "/media/products/pap/screen5.webp",
        "/media/products/pap/screen6.webp",
      ],
    },
    {
      id: 2,
      name: "Pharma Track",
      logo: "/media/products/pharma-track/logo.webp",
      title:
        "A web-based system and mobile-based application designed for pharmaceutical companies to track sales on a real-time basis, along with the performance and real time engagement of medical sales representatives. The application features active dashboards, audio-video conferencing, doctors/representative ratings, team/tasks management, performance metrics, real-time communication, tracking, alerts, chats, graphs, meeting follows ups and more.",
      webCover: "/media/products/pharma-track/web-cover.webp",
      mobCover: "/media/products/pharma-track/mob-cover.webp",
      features: [
        "Create monthly visits and calls.",
        "Track live location, record and detail all aspects of your sales process.",
        "Electronic documentation containing product information, with clinical trial data supporting the manufacturer’s claims.",
        "Learn selling, product knowledge and communication skills through our platform.",
        "Analyze and improve the performance of sales. Forecast the next vision of targets and achievements to generate more revenue.",
      ],
      screens: [
        "/media/products/pharma-track/screen1.webp",
        "/media/products/pharma-track/screen2.webp",
        "/media/products/pharma-track/screen3.webp",
        "/media/products/pharma-track/screen4.webp",
        "/media/products/pharma-track/screen5.webp",
        "/media/products/pharma-track/screen6.webp",
      ],
    },
    {
      id: 3,
      name: "MeetUp",
      logo: "/media/products/meetup/logo.webp",
      title:
        "A cloud-based peer-to-peer communication software platform that allows users to create virtual spaces that powers all of your communication needs, including meetings, chat, phone, webinars, and online events. It has screen sharing and document sharing among other capabilities and can be a great tool for distance learning. Our application gallery allows users to experience features of all their favourite applications.",
      webCover: "/media/products/meetup/web-cover.webp",
      features: [
        "Schedule and host meetings.",
        "Multiple participents can attend meeting at the same time.",
        "Live chatting during meeting.",
        "Live audio and video sharing.",
        "File sharing during meeting.",
        "Edit and maintain profile.",
      ],
      screens: [
        "/media/products/meetup/screen1.webp",
        "/media/products/meetup/screen2.webp",
        "/media/products/meetup/screen3.webp",
        "/media/products/meetup/screen4.webp",
      ],
    },
    {
      id: 4,
      name: "HealthE",
      logo: "/media/products/healthe/logo.webp",
      title:
        "A state of the art health care mobile application covering patients, doctors, pharmaceuticals, labs, hospitals, first responders and more.",
      webCover: "/media/products/healthe/web-cover.webp",
      mobCover: "/media/products/healthe/mob-cover.webp",
      features: [
        "Doctors and patients can create and maintain their profiles.",
        "Patients have access to a large network of doctors, allowing them to book appointments with specialists according to their needs.",
        "Patient profile can be updated after every visit, allowing doctors access to medical history, lab reports and prescription of the patient.",
        "Doctors can view their appointments and schedules in advance and make modifications without hassle.",
        "Allows e-visits with doctors via audio and video calling.",
        "Patient can pay their medical fee via application.",
      ],
      screens: [
        "/media/products/healthe/screen1.webp",
        "/media/products/healthe/screen2.webp",
        "/media/products/healthe/screen3.webp",
        "/media/products/healthe/screen4.webp",
        "/media/products/healthe/screen5.webp",
      ],
    },
    {
      id: 5,
      name: "Education Portal",
      logo: "/media/products/education-portal/logo.webp",
      title:
        "This is a web and mobile-based management system for schools, colleges, and universities.",
      webCover: "/media/products/education-portal/web-cover.webp",
      mobCover: "/media/products/education-portal/mob-cover.webp",
      features: [
        "The system keeps record, make admin, make multiple campuses, classes and sections as well.",
        "Make and assign subjects with the respective syllabus.",
        "Online classes with audio and video conferencing and chatting functionality along with a whiteboard feature.",
        "Create and assign assignments with submission of digital as well as non-digital (hand-written) assignments with a feature of auto-cropping along with grading process.",
        "Various exam conduction processes available and generate results.",
        "Convert report card to PDF.",
        "Schedule parents-teacher meetings.",
      ],
      screens: [
        "/media/products/education-portal/screen1.webp",
        "/media/products/education-portal/screen2.webp",
        "/media/products/education-portal/screen3.webp",
        "/media/products/education-portal/screen4.webp",
        "/media/products/education-portal/screen5.webp",
        "/media/products/education-portal/screen6.webp",
      ],
    },

    {
      id: 6,
      name: "Smart S&D",
      logo: "/media/products/smart-s&d/logo.webp",
      title:
        "Smart S&D is a mobile-based sales and distribution tracking tool for FMCGs and other industries, which helps businesses to control and monitor their sales and distribution. The application also allows performance tracking of their on-ground sales personnel. The application is designed to apply and maintain salesforce effectiveness across business functions to help maximize productivity and profitability.",
      mobCover: "/media/products/smart-s&d/mob-cover.webp",
      features: [
        "Create unlimited number of salesman, customers, products and shops.",
        "Assign multiple targets to salesman.",
        "Performance measure of salesman including their monthly target, achievement percentage, run rate etc.",
        "Make orders.",
        "Print Invoice.",
      ],
      screens: [
        "/media/products/smart-s&d/screen1.webp",
        "/media/products/smart-s&d/screen2.webp",
        "/media/products/smart-s&d/screen3.webp",
        "/media/products/smart-s&d/screen4.webp",
        "/media/products/smart-s&d/screen5.webp",
        "/media/products/smart-s&d/screen6.webp",
      ],
    },
    {
      id: 7,
      name: "Smart S&D Pharma",
      logo: "/media/products/pharma-s&d/logo.webp",
      title:
        "A mobile-based tracking application specifically designed for pharmaceutical companies to track, control and monitor sales and distribution of their medicines. The application also allows performance tracking of their on-ground sales personnel and maintain salesforce effectiveness across business functions to help maximize productivity and profitability.",
      mobCover: "/media/products/pharma-s&d/mob-cover.webp",
      features: [
        "The application allows the user to set their geographical location as they visit the clients.",
        "Administrator and user can view the salesperson performance monthly and on weekly basis.",
        "Can view the summary of target orders assigned by administrator as well summary of orders completed.",
        "The application provides sales representative daily working routes and schedules.",
      ],
      screens: [
        "/media/products/pharma-s&d/screen1.webp",
        "/media/products/pharma-s&d/screen2.webp",
        "/media/products/pharma-s&d/screen3.webp",
        "/media/products/pharma-s&d/screen4.webp",
        "/media/products/pharma-s&d/screen5.webp",
        "/media/products/pharma-s&d/screen6.webp",
      ],
    },
    {
      id: 8,
      name: "Vits HRMS",
      logo: "/media/products/vits/logo.webp",
      title:
        "Vits is an Online Portal to fulfill the need of Human Resource Management of companies.",
      webCover: "/media/products/vits/web-cover.webp",
      features: [
        "The system features includes all the activities from identifying, attracting, screening, shortlisting, interviewing, selecting, hiring, and onboarding.",
        "Online documents uploading.",
        "A separate section for social media integration (Events, WebCasts, Albums, Performance Badges, Discussions/Blogs).",
        "Coverage of Health Insurance, Leaves, and Timesheets.",
      ],
      screens: [
        "/media/products/vits/screen1.webp",
        "/media/products/vits/screen2.webp",
        "/media/products/vits/screen3.webp",
        "/media/products/vits/screen4.webp",
        "/media/products/vits/screen5.webp",
        "/media/products/vits/screen6.webp",
      ],
    },
    {
      id: 9,
      name: "Sales & Predict",
      logo: "/media/products/sales&predict/logo.webp",
      title:
        "This system covers the scope to predict future sales and provide recommendations for business development.",
      webCover: "/media/products/sales&predict/web-cover.webp",
      features: [
        "Predict future sales of specific branch and whole network.",
        "Predict future inventory statuses of specific branch and whole store network.",
        "Provides recommendation to improve sales.",
        "Provides recommendation to manage inventory.",
      ],
      screens: [
        "/media/products/sales&predict/screen1.webp",
        "/media/products/sales&predict/screen2.webp",
        "/media/products/sales&predict/screen3.webp",
        "/media/products/sales&predict/screen4.webp",
      ],
    },
    {
      id: 10,
      name: "Cancer Classification",
      logo: "/media/products/cancer-classification/logo.webp",
      title:
        "The Cancer Classification is a web-based AI system that detects 7 different types of skin cancer. It takes dermatoscopic images as an input to identify the type of skin cancer, with an accuracy rate of 97%.",
      webCover: "/media/products/cancer-classification/web-cover.webp",
      features: [
        "The system has capability to detect the seven following types of skin cancer: Melanocytic Nevi, Melanoma, Benign Keratosis, Basal Cell Carcinoma, Actinic Keratoses, Vascular Lesions, Dermatofibroma",
        "The system takes dermatoscopic images as an input and identify the type of skin cancer.",
        "It detects the cancer type with up to 97% accuracy.",
      ],
      screens: [
        "/media/products/cancer-classification/screen1.webp",
        "/media/products/cancer-classification/screen2.webp",
        "/media/products/cancer-classification/screen3.webp",
        "/media/products/cancer-classification/screen4.webp",
        "/media/products/cancer-classification/screen5.webp",
        "/media/products/cancer-classification/screen6.webp",
        "/media/products/cancer-classification/screen7.webp",
      ],
    },
    {
      id: 11,
      name: "Crime Detection",
      logo: "/media/products/crime-detection/logo.webp",
      title:
        "A web-based product designed to detect criminal activity, with the help of Artificial Intelligence.",
      webCover: "/media/products/crime-detection/web-cover.webp",
      features: [
        "The system is flexible to detect the presence of civilian in prohibited place as crime scene.",
        "It is capable enough to detect the law enforcements activities as not a crime.",
      ],
      screens: [
        "/media/products/crime-detection/screen1.webp",
        "/media/products/crime-detection/screen2.webp",
        "/media/products/crime-detection/screen3.webp",
        "/media/products/crime-detection/screen4.webp",
        "/media/products/crime-detection/screen5.webp",
      ],
    },
    {
      id: 12,
      name: "Facial Recognition System",
      logo: "/media/products/face-recognition/logo.webp",
      title:
        "This product detects faces using Artificial Intelligence and recognizes facial expressions.",
      webCover: "/media/products/face-recognition/web-cover.webp",
      features: [
        "It supports the ability to add new unknown faces in real-time.",
        "It is flexible enough to remove any known face if needed.",
        "With the recognition of known persons it also provides details of the person  to make the scope broader.",
      ],
      screens: [
        "/media/products/face-recognition/screen1.webp",
        "/media/products/face-recognition/screen2.webp",
        "/media/products/face-recognition/screen3.webp",
        "/media/products/face-recognition/screen4.webp",
        "/media/products/face-recognition/screen5.webp",
      ],
    },
    {
      id: 13,
      name: "Facial Expression Recognition System",
      logo: "/media/products/facial-expression-recognition/logo.webp",
      title:
        "A web-based system which is able to detect face and then recognize it's expressions.",
      webCover: "/media/products/facial-expression-recognition/web-cover.webp",
      features: [
        "The system is able to detect the following expressions: Sad, Angry, Happy, Disengaged, Fear",
        "The system recognizes the expressions of a person as well as the time duration of a specific expression.",
        "It recognizes multiple face expressions at a time which helps in tracking behaviors in sessions.",
      ],
      screens: [
        "/media/products/facial-expression-recognition/screen1.webp",
        "/media/products/facial-expression-recognition/screen2.webp",
        "/media/products/facial-expression-recognition/screen3.webp",
        "/media/products/facial-expression-recognition/screen4.webp",
        "/media/products/facial-expression-recognition/screen5.webp",
      ],
    },
    {
      id: 14,
      name: "AI based Sign Language Conversion",
      logo: "/media/products/sign-language/logo.webp",
      title:
        "The scope of this project is to detect signs of American Sign Language (ASL).",
      webCover: "/media/products/sign-language/web-cover.webp",
      features: [
        "The system is able to detects the signs of ASL with an accuracy of 98%.",
        "After detection, it converts the signs into text.",
        "In order to support editing, the system can also detect the signs of space and delete.",
        "This system is used to make words or sentences from signs.",
      ],
      screens: [
        "/media/products/sign-language/screen1.webp",
        "/media/products/sign-language/screen2.webp",
        "/media/products/sign-language/screen3.webp",
        "/media/products/sign-language/screen4.webp",
        "/media/products/sign-language/screen5.webp",
      ],
    },
    {
      id: 15,
      name: "L'oreal SYNC",
      logo: "/media/products/loreal/logo.png",
      title:
        "The Trade Management System is a user-friendly web app that simplifies budget management, BSR generation, and claim settlements. It streamlines financial operations, ensuring transparency and efficient financial management.",
      webCover: "/media/products/loreal/1 (2).png",
      features: [
        "Different users with specific roles can sign in to the system using their Microsoft accounts. This ensures secure access and streamlines the login process through single sign-on.",
        "Users can upload budget summaries to the system as the starting point of the process.",
        "The system generates BSRs based on the uploaded budget summaries, providing an overview of the budget allocation and distribution.",
        "Users with appropriate roles can review and approve or reject BSRs. Each division has designated users responsible for approving BSRs related to their respective areas.",
        "Once the BSRs are approved, distributors can make claims based on the allocated budget. These claims represent specific expenses or requirements.",
        "Claims submitted by distributors go through an approval workflow, where designated approvers review and decide whether to approve or reject the claims. This process ensures proper validation and control over budget allocation.",
        "Approved claims eventually get settled.",
      ],
      screens: [
        "/media/products/loreal/1 (3).png",
        "/media/products/loreal/1 (1).png",
        "/media/products/loreal/1 (4).png",
        "/media/products/loreal/1 (5).png",
        "/media/products/loreal/1 (6).png",
      ],
    },
    {
      id: 16,
      name: "S&D IAC",
      logo: "/media/products/iac/logo.png",
      title:
        "Maximize salesforce effectiveness and monitor sales/distribution in FMCGs and other industries.",
      webCover: "/media/products/iac/iac-new-cover.png",
      mobCover: "/media/products/iac/iac-mob.png",
      features: [
        "Create an unlimited number of salespersons, customers, products, and shops.",
        "Assign multiple targets to each salesperson.",
        "Measure the performance of salespersons, including their monthly targets, product category-wise performance, and monitoring of productive or non-productive shops.",
        "Place orders.",
        "Generate invoices.",
        "Track daily visits.",
        "Daily Sales Report Booking and execute.",
      ],
      screens: [
        "/media/products/iac/iac-dash.png",
        "/media/products/iac/iac-1.jpg",
        // "/media/products/iac/iac-2.jpg",
        "/media/products/iac/iac-7.png",
        "/media/products/iac/iac-3.jpg",

      ],
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [src, setSRC] = React.useState("");

  const handleMouse = (action,image) => {
    setOpen(action)
    setSRC(image)
  };


  return (
    <React.Fragment>
      <Header />
      <section id="product-details-sec">
        {
          <React.Fragment>
            <div className="container-fluid">
              <div className="product-overview text-center">
                <img
                  src={ProductsList[ProductId].logo}
                  alt=""
                  className="product-logo"
                />
                <p>{ProductsList[ProductId].title}</p>
              </div>
              {ProductsList[ProductId].webCover ? (
                <div className="product-cover-img text-center">
                  <h2>
                    {ProductsList[ProductId].name}
                    <sup className="ml-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#ffffff"
                        class="bi bi-badge-tm"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.295 11V5.995H7V5H2.403v.994h1.701V11h1.19zm3.397 0V7.01h.058l1.428 3.239h.773l1.42-3.24h.057V11H13.5V5.001h-1.2l-1.71 3.894h-.039l-1.71-3.894H7.634V11h1.06z"></path>
                        <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"></path>
                      </svg>
                    </sup>
                  </h2>
                  <h4>
                    (Web)
                  </h4>
                  <img
                    src={ProductsList[ProductId].webCover}
                    alt=""
                    className="web-cover"
                  />
                </div>
              ) : (
                ""
              )}
              {ProductsList[ProductId].mobCover ? (
                <div className="product-cover-img text-center">
                  <h2>
                    {ProductsList[ProductId].name}
                    <sup className="ml-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#ffffff"
                        class="bi bi-badge-tm"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.295 11V5.995H7V5H2.403v.994h1.701V11h1.19zm3.397 0V7.01h.058l1.428 3.239h.773l1.42-3.24h.057V11H13.5V5.001h-1.2l-1.71 3.894h-.039l-1.71-3.894H7.634V11h1.06z"></path>
                        <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"></path>
                      </svg>
                    </sup>
                  </h2>
                  <h4>
                    (Android)
                  </h4>
                  <img
                    src={ProductsList[ProductId].mobCover}
                    alt=""
                    className="mob-cover"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <Row className="m-0 py-4 d-flex align-items-center justify-content-center">
              <Col xs={12} md={6} className="px-0">
                <div className="product-features-list">
                  <h2 className="mb-5">Features</h2>
                  <ul>
                    {ProductsList[ProductId].features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={6} className="px-0">
                <div className="product-screens-list">
                  <Slider {...settings}>
                    {ProductsList[ProductId].screens.map((screen) => (
                      <div>
                        <div  className={`product-screens d-flex align-items-center justify-content-center`}         
                            // onMouseEnter={()=>handleMouse(true,screen)}
                            // onMouseLeave={()=>handleMouse(false,screen)}
                            >
                          <img src={screen} alt="" />
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        }
      </section>
      <section className="other-products-sec pb-5">
        <h3 className="my-3 text-center">All Products</h3>
        <Products />
      </section>
      <ImageDialoge src={src} open={open}></ImageDialoge>
    </React.Fragment>
  );
};

export default ProductDetails;
