import React from "react";
import { Row, Col } from "react-bootstrap";
import InputField from './../formFields/inputField';
import SelectField from "../formFields/selectField";
import TextAreaField from "../formFields/textAreaField.jsx";

const starting_project = [
  {
    value: 'None',
    label: 'How soon you are ready to start?'
  },
  {
    value: 'Immediate',
    label: 'Immediate'
  },
  {
    value: '1-2 Weeks',
    label: '1-2 Weeks'
  },
  {
    value: '2-4 Weeks',
    label: '2-4 Weeks'
  }
];

const completion_project = [
  {
    value: 'None',
    label: 'Select expected completion timeline'
  },
  {
    value: 'Less than 1 month',
    label: 'Less than 1 month'
  },
  {
    value: '2-3 months',
    label: '2-3 months'
  },
  {
    value: 'More than 5 months',
    label: 'More than 5 months'
  }
];

const project_types = [
  {
    value: 'Select your project type',
    label: 'Select your project type'
  },
  {
    value: 'New development',
    label: 'New development'
  },
  {
    value: 'Maintenance/Service',
    label: 'Maintenance/Service'
  },
];

const price_ranges = [
  {
    value: 'Select your expected price range',
    label: 'Select your expected price range'
  },
  {
    value: '$10,000',
    label: '$10,000'
  },
  {
    value: '$20,000',
    label: '$20,000'
  },
  {
    value: '$30,000',
    label: '$30,000'
  },
  {
    value: '$40,000',
    label: '$40,000'
  },
]

export default function ProjectRequirements (props) {
  const {
    formField: {
      project_desc,
      // start_project,
      // complete_project,
      // project_type,
      // language_required,
      // no_of_pages,
      // design_wireframe,
      // price_range,
      // reference_url
    }
  } = props;
  return (
    <React.Fragment>
      {/* <Row className="m-0">
        <Col xs={12} md={6}>
          <SelectField id="start_project" name={start_project.name} label={start_project.label} data={starting_project}/>
        </Col>
        <Col xs={12} md={6}>
          <SelectField id="complete_project" name={complete_project.name} label={complete_project.label} data={completion_project}/>
        </Col>
      </Row>
      <Row className="m-0">
        <Col xs={12} md={6}>
          <SelectField id="project_type" name={project_type.name} placeholder={project_type.label} data={project_types}/>
        </Col>
        <Col xs={12} md={6}>
          <InputField id="language_required" name={language_required.name} placeholder={language_required.label} data={language_required} />
        </Col>
      </Row>
      <Row className="m-0">
        <Col xs={12} md={6}>
          <InputField id="no_of_pages" name={no_of_pages.name} placeholder={no_of_pages.label} type="number" min="1"/>
        </Col>
        <Col xs={12} md={6}>
          <div className="col-12 form-group mt-1">
            <label className="mt-2 w-100" htmlFor="design_wireframe">
              Do you have a Design/Wireframe?
            </label>
            <div className="form-check form-check-inline px-3">
              <InputField className="form-check-input" type="radio" id="yes" value="yes" name={design_wireframe.name}/>
              <label className="form-check-label" htmlFor="yes">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline px-3">
              <InputField className="form-check-input" type="radio" id="no" value="no" name={design_wireframe.name} checked/>
              <label className="form-check-label" htmlFor="no">
                No
              </label>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="m-0">
        <Col xs={12} md={6}>
          <SelectField id="price_range" name={price_range.name} label={price_range.label} data={price_ranges}/>
        </Col>
        <Col xs={12} md={6}>
          <InputField id="reference_url" name={reference_url.name} placeholder={reference_url.label} />
        </Col>
      </Row> */}
      <Row className="m-0">
        <Col xs={12}>
          <TextAreaField id="project_desc" name={project_desc.name} placeholder={project_desc.label}/>
        </Col>
      </Row>
    </React.Fragment>
  );
};
