import React from "react";
import { Row, Col } from 'react-bootstrap';

const Partners = () => {
    return (
        <section id="Partner-sec">
            {/* <img className="img-bg" src="/media/clients-bg.svg" alt="" /> */}
            <Row className="client-descript-block">
                <div className="container-fluid">
                    <Col>
                        <h4 className="title-yellow-bar mx-3 mb-3">Our Partners</h4>
                        <p className="overview-white mx-3">Meet our trusted partners</p>
                    </Col>
                </div>
            </Row>
            <Row className="client-list-block">
                <div className="container-fluid text-center">
                    <ul className="row list-unstyled text-center m-0  d-inline-flex justify-content-center ">
                        <li>
                            {/* <span className="client-logo6"></span> */}
                            <img style={{maxWidth: "170px" , maxHeight: "150px"}} src="/media/partners/Blaylock.png" alt="" />
                            <a href="#" class="card__button">Blaylock Engineering</a>
                        </li>
                        <li>
                            {/* <span className="client-logo6"></span> */}
                            <img style={{maxWidth: "220px" , maxHeight: "158px"}} src="/media/partners/Shoreditch.png" alt="" />
                            <a href="#" class="card__button">Shoreditch Tech</a>
                        </li>
                        <li>
                            {/* <span className="client-logo6"></span> */}
                            <img style={{maxWidth: "170px" , maxHeight: "150px"}} src="/media/partners/Rapid.png" alt="" />
                            <a href="#" class="card__button">Rapid Compute</a>
                        </li>
                    </ul>
                </div> 
            </Row>
        </section>
    );
}

export default Partners;