import React, { useEffect, useState } from 'react';
import ReactGlobe from 'react-globe';
import { defaultBarMarkerOptions, defaultDotMarkerOptions } from 'react-globe';
import LinearProgress from '@mui/material/LinearProgress';
// import optional tippy styles for tooltip support
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';


const markers = [
  // {
  //   "id": "marker1",
  //   "city": "(Digital Landscape) 26-A, Ground Floor, Manzar-e-Alam Road, N.K.C.H Society, Karachi",
  //   "color": "red",
  //   "coordinates": [
  //     24.87847528189816, 67.06317764085235
  //   ],
  //   "value": 50
  // },
  {
    "id": "marker2",
    "city": "(Shoreditch Tech) 86-90, Paul Street, London EC2A 4NE. United Kingdom",
    "color": "red",
    "coordinates": [
      51.525784381797365, -0.08363027352727587
    ],
    "value": 50
  },

  {
    "id": "marker3",
    "city": "(BLAYLOCK ENGINEERING) 808 N Garfield Ave Unit A, Alhambra, CA 91801",
    "color": "red",
    "coordinates": [
      34.10290092181953, -118.13175424725131
    ],
    "value": 50
  },
]
let animations = []

const Globe = ({ coordinates }) => {
  // support rendering markers with simple data
  const [globe, setGlobe] = useState(null);
  const [globeLoaded, setGlobeLoaded] = useState(false);
  debugger
  const [animationSequence, setAnimationSequence] = useState()


  // simple and extensive options to configure globe
  // const options = {
  //   ambientLightColor: 'red',
  //   cameraRotateSpeed: 0.5,
  //   focusAnimationDuration: 2000,
  //   focusEasingFunction: ['Linear', 'None'],
  //   pointLightColor: 'gold',
  //   pointLightIntensity: 1.5,
  //   globeGlowColor: 'blue',
  //   markerTooltipRenderer: marker => `${marker.city} (${marker.value})`,
  // };
  console.log("globe instance", globe); // captured globe instance with API methods


  const options = {
    // cameraDistanceRadiusScale: 10,
    cameraMaxDistanceRadiusScale: 15,
    cameraZoomSpeed: 4,
    enableCameraZoom: false,
    enableCameraAutoRotate: false,
    enableCameraRotate: false,
    enableMarkerGlow: false,
    markerRadiusScaleRange: [0.1, 0.5],
    markerType: "bar",

    enableMarkerTooltip: true,
    markerEnterAnimationDuration: 3000,
    markerEnterEasingFunction: ['Bounce', 'InOut'],
    markerExitAnimationDuration: 3000,
    markerExitEasingFunction: ['Cubic', 'Out'],
    markerTooltipRenderer: marker =>
      `${marker.city}`,
    markerRadiusScaleRange: [0.1, 0.5],
    

  }

  function ShowGlobe() {
    setGlobeLoaded(true)
  }

  useEffect(() => {
    animations = []
    animations.push(
      {
        coordinates: [coordinates[0], coordinates[1]],
        focusAnimationDuration: 3000,
        focusDistanceRadiusScale: 3,
        focusEasingFunction: ['Cubic', 'InOut'],
      }
    )
    setAnimationSequence(animations)
    setTimeout(ShowGlobe, 1000);
  }, [coordinates])
  // simple component usage
  return (
    <>
      {!globeLoaded ? <div className="container my-5 contact-div" style={{ padding: "15px", textAlign: "center" }}>
        <LinearProgress />
        <span style={{ fontWeight: "500" }}>
          Loading Globe...
        </span>
      </div>
        :
        <div style={{opacity: typeof(globe) === "object" ? 1 : 0}}>
        <ReactGlobe
          animations={animationSequence}
          onGetGlobe={setGlobe}
          initialZoom={1}
          // onGlobeClick={(event) => event.preventDefault()}
          // onGlobeTextureLoaded={() => setGlobeLoaded(true)}
          height="80vh" width="100%" markers={markers} options={options}
        />
        </div>
      }
    </>
  );
}

export default React.memo(Globe);