import React from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Header from "../components/header.jsx";
import ButtonMailto from "./mail-to.jsx";
import JobsData from "../JobsData.js";

const JobDetails = () => {
  let currentDay = new Date();
  let currentYear = currentDay.getFullYear();
  let currentMonth = currentDay.getMonth() + 1;
  let currentDate = currentDay.getDate();
  let today = currentYear + "-" + currentMonth + "-" + currentDate;
  const { id } = useParams();
  const job = JobsData[id - 1];

  return (
    <React.Fragment>
      <Header />
      <section id="job-details-sec">
        <div className="container-fluid">
          <Row className="m-0">
            <Col xs={12} className="job-details-content">
              <h4 className="title-blue-bar ml-5 mt-3 mb-4">Join Our Team</h4>
              <p className="overview-black w-75 ml-5 mb-5">
                To apply, please email hr@dls-global.com and mention the
                applying position title in email subject field.
              </p>
            </Col>
          </Row>
          <Row className="m-0">
            <Col xs={12} className="px-0">
              <div className="blue-border d-flex flex-column justify-content-center align-items-center p-5">
                <p className="job-icon-blue d-flex flex-column justify-content-center align-items-center">
                  <img src="/media/icon3.svg" alt="" className="job-icon-img" />
                </p>
                <div className="job-details">
                  <h4 className="job-title-blue text-center py-3">
                    {job.title}
                  </h4>
                  <h4>Job Description</h4>
                  <p className="mb-4 w-100">{job.desc}</p>
                  <h4>Required Skills</h4>
                  <ol>
                    <li>
                      <h5>Professional Skills</h5>
                      <ul>
                        {job.professionalSkills.map((skill, index) => (
                          <li key={index}>{skill}</li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <h5>Personal Skills</h5>
                      <ul>
                        {job.personalSkills.map((skill, index) => (
                          <li key={index}>{skill}</li>
                        ))}
                      </ul>
                    </li>
                  </ol>
                  <h4>Key Responsibilities</h4>
                  <ul>
                    {job.responsibilities.map((skill, index) => (
                      <li key={index}>{skill}</li>
                    ))}
                  </ul>
                  <h4>Qualification</h4>
                  <p>{job.qualification}</p>
                  <h4>Job Details</h4>
                  <ul>
                    <li>Industry: {job.details.industry}</li>
                    <li>Total Positions: {job.details.position}</li>
                    <li>Job Shift: {job.details.shift}</li>
                    <li>Job Type: {job.details.type}</li>
                    <li>Job Location: {job.details.location}</li>
                    <li>Gender: {job.details.gender}</li>
                    <li>Age: {job.details.age}</li>
                    <li>Apply Before: {today}</li>
                  </ul>
                  <div className="note py-3">
                    <p>
                      <span>Note: </span>We are an equal opportunity employer.
                      Minorities, women, and individuals with disabilities are
                      encouraged to apply. It is our company's policy not to
                      discriminate against any employee or applicant for
                      employment on the basis of race, colour, creed, religion,
                      national origin, ancestry, age, sex or gender, marital
                      status, physical or mental disability, or any other
                      characteristic protected by applicable laws and
                      ordinances.
                    </p>
                    <p>
                      To apply for this position please send an email at
                      careers@dls-global.com by clicking on apply now button and
                      mention the applying position title in email subject
                      field.
                    </p>
                  </div>
                  <ButtonMailto
                    label="Apply Now"
                    mailto="mailto:careers@dls-global.com"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* <Footer /> */}
    </React.Fragment>
  );
};

export default JobDetails;
