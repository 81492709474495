import React, {useState} from 'react';
import { at } from 'lodash';
import { useField, ErrorMessage } from 'formik';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function PhoneDropdown (props) {
    const { errorText, type, ...rest } = props;
    const [field, meta] = useField(props);
    const [value, setValue] = useState()
    console.log(meta)
      debugger
    function _renderHelperText() {
      const [touched, error] = at(meta, 'touched', 'error');
      if (touched && error) {
        return error;
      }
    }
    const GetLocation =()=>{
      if(props.currentLocation === "pakistan"){
        return "pk"
      }
      else if(props.currentLocation === "uk"){
        return "gb"
      }
      return "us"
    }
    return (
      <React.Fragment>
        <PhoneInput
            country={GetLocation()}
            value={value}
            onChange={
                setValue
            }
            inputClass={"mt-4"}
            inputProps={{
                            id: props.id,
                            name: props.name,
                            // value: field.value,
                            // onChange: field.onChange,
                            onBlur: field.onChange
                       }}
        />
        <ErrorMessage name={field.name} style={{color: "#ff0000"}}/>
      </React.Fragment>
    );
  }