import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Formik, Form } from "formik";
import axios from "axios";

import PersonalInfo from "./rfqForm/personalInfo.jsx";
import CompanyProfile from "./rfqForm/companyProfile.jsx";
import ProjectRequirements from "./rfqForm/projectRequirements.jsx";
// import TechnicalRequirements from "./rfqForm/technicalRequirements.jsx";
// import Others from "./rfqForm/others.jsx";

import validationSchema from "./rfqForm/formModel/validationSchema.js";
import formModel from "./rfqForm/formModel/formModel.js";
import initialValues from "./rfqForm/formModel/initialValues.js";

const steps = [
  "Personal Information",
  "Company Profile (if any)",
  "Project Requirements",
  // "Technical Requirements",
  // "Others",
];
const { formId, formField } = formModel;

function _renderStepContent(step, formik) {
  switch (step) {
    case 0:
      return <PersonalInfo formField={formField} />;
    case 1:
      return <CompanyProfile formField={formField} />;
    case 2:
      return <ProjectRequirements formField={formField} />;
    // case 3:
    //   return <TechnicalRequirements formField={formField} />;
    // case 4:
    //   return <Others formField={formField} formik={formik} />;
  }
}

export default function Rfq() {
  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  async function submitData(contentType, formData) {
    await axios({
      url: "https://devapp.digitallandscape.com.pk:1401/api/v1/User/Rfq",
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": contentType,
      },
    })
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        console.log("this is error", error);
      });
  }

  async function _submitForm(values, actions) {
    debugger
    var new_msg = {
      id: 0,
      Name: values.full_name,
      Email: values.email_address,
      // PhoneNo: values.phone_num,
      Country: values.country,
      Company: values.company,
      // PostalCode: values.postal_code,
      // PrefferedShift: values.pref_time,
      // Company: values.company_name,
      // CompanyUrl: values.company_url,
      // BusinessDomain: values.business_domain,
      // Designation: values.designation,
      // Role: values.customer_vendor,
      // EstimatedStartTime: values.start_project,
      // ExpectedEndTime: values.complete_project,
      // ProjectType: values.project_type,
      // RequiredLanguage: values.language_required,
      // NoOfScreens: values.no_of_pages,
      // IsDesignAvailable: values.design_wireframe,
      // ExpectedPriceRange: values.price_range,
      // ReferenceUrl: values.reference_url,
      // FrontEndTech: values.frontend,
      // BackendTech: values.backend,
      // Database: values.required_db,
      // DatabaseType: values.database_type,
      ProjectDescription: values.project_desc,
      // ProposalFile: values.uploaded_file,
    };

    const formData = new FormData();

    formData.append("Id", 0);
    formData.append("Name", new_msg.Name);
    formData.append("Email", new_msg.Email);
    // formData.append("PhoneNo", new_msg.PhoneNo);
    formData.append("Country", new_msg.Country);
    formData.append("Company", new_msg.Company);
    // formData.append("PostalCode", new_msg.PostalCode);
    // formData.append("PrefferedShift", new_msg.PrefferedShift);
    // formData.append("CompanyUrl", new_msg.CompanyUrl);
    // formData.append("Company", new_msg.Company);
    // formData.append("BusinessDomain", new_msg.BusinessDomain);
    // formData.append("Designation", new_msg.Designation);
    // formData.append("Role", new_msg.Role);
    // formData.append("EstimatedStartTime", new_msg.EstimatedStartTime);
    // formData.append("ExpectedEndTime", new_msg.ExpectedEndTime);
    // formData.append("ProjectType", new_msg.ProjectType);
    // formData.append("RequiredLanguage", new_msg.RequiredLanguage);
    // formData.append("NoOfScreens", new_msg.NoOfScreens);
    // formData.append("IsDesignAvailable", new_msg.IsDesignAvailable);
    // formData.append("ExpectedPriceRange", new_msg.ExpectedPriceRange);
    // formData.append("ReferenceUrl", new_msg.ReferenceUrl);
    // formData.append("FrontEndTech", new_msg.FrontEndTech);
    // formData.append("BackendTech", new_msg.BackendTech);
    // formData.append("Database", new_msg.Database);
    // formData.append("DatabaseType", new_msg.DatabaseType);
    formData.append("ProjectDescription", new_msg.ProjectDescription);
    // formData.append("Proposal_file", new_msg.ProposalFile);

    submitData("multipart/form-data", formData);
    actions.setSubmitting(false);
    setActiveStep(activeStep + 1);
  }
  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }
  function _handleBack(formik) {
    setActiveStep(activeStep - 1);
    _renderStepContent(activeStep, formik);
  }

  return (
    <section id="rfq-sec">
      <h4 className="title-blue-bar ml-5 mb-2">Request for Quote</h4>
      <div className="rfq-sec">
        {/* <img src="/media/rfq-bg.png" alt="" className="rfq-sec-bg" /> */}
        <Row className="rfq-content-block">
          <Col xs={12} md={3} className="rfq-content m-md-3">
            <h4 className="pb-2">
              Do you have an idea? <br></br> let’s get in touch!
            </h4>
            <p>
              Contact us and we will not let you walk away
              disappointed.
            </p>
            <img src="/media/map.svg" alt="" />
          </Col>
          <Col xs={12} md={8} className="rfq-form">
            {/* <ul id="progressbar" activeStep={activeStep} className="list-unstyled d-flex justify-content-between mx-4">
              {steps.map(label => (
                <li key={activeStep}>{label}</li>
              ))}
            </ul> */}
            {activeStep === steps.length && success ? (
              <h4 className="success-msg mt-5 text-center">
                Thank you! Your Request for Quote has been submitted
                successfully. Within 24 hours, one of our Development Strategist
                will contact you.
              </h4>
            ) : activeStep === steps.length && !success ? (
              <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                className="text-center mt-5"
              />
            ) : (
              <>
                <h4 className="active-item w-100 mt-4">{steps[activeStep]}</h4>
                <Formik
                  initialValues={initialValues}
                  validationSchema={currentValidationSchema}
                  onSubmit={_handleSubmit}
                >
                  {(formik) => (
                    <React.Fragment>
                      <Form id={formId}>
                        {_renderStepContent(activeStep, formik)}
                        <div>
                          {activeStep !== 0 && (
                        <button onClick={(formik) => _handleBack()} type="button" className="back-btn">
                          Back
                        </button>
                      )}
                          <button
                            type="submit"
                            disabled={formik.isSubmitting}
                            className="next-btn"
                          >
                            {isLastStep ? "Request for Quote" : "Next"}
                          </button>
                        </div>
                      </Form>
                    </React.Fragment>
                  )}
                </Formik>
              </>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
}
