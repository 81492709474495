import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ImageDialoge({open, src }) {

  return (
    <div>
      <Dialog open={open} 
       fullWidth={true}
       maxWidth={"md"}
      >
        <DialogContent >
            <img src={src} style={{width : "650px"}}></img>
        </DialogContent>
      </Dialog>
    </div>
  );
}