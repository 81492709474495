import React from "react";
import Header from "../components/header.jsx";
import LandingSlider from "../components/landing-slider.jsx";
import Products from "./our-products.jsx";
import Services from "./our-services.jsx";
import Technologies from "./our-technologies.jsx";
import Partners from "./our-partners.jsx";
import Clients from "./our-clients.jsx";
import Testimonials from './client-testimonials';
import EngagModels from "./engagement-models.jsx";
import Rfq from "../components/rfq.jsx";
import { useLocation } from "react-router-dom";
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const LandingPage = () => {
    const location = useLocation();
    return ( 
        <React.Fragment>
            <Header locationName={location.pathname}/>
            <LandingSlider/> 
            {/* <section className="home-banner-sec">
                <div className="row w-100">
                    <div className="container">
                        <div className="col-lg-7 p-0 d-flex flex-wrap justify-content-center">
                            <div className="content-block">
                                <h4 className="title-banner-bar">Digital Landscape</h4>
                                <p>
                                    <svg width="10px" className="mb-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-quote-left fa-w-16 fa-2x"><path fill="currentColor" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z" className=""></path></svg>
                                    Providing the simplest solution for the most complex problems
                                    <svg width="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-quote-right fa-w-16 fa-2x"><path fill="currentColor" d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z" className=""></path></svg>
                                </p>
                            </div>
                        </div> 
                    </div>
                </div> 
                <div className="home-content-block">
                </div>
            </section> */}
            <LazyLoadComponent>
                <Products />   
            </LazyLoadComponent>
            <LazyLoadComponent>
                <Services/>
            </LazyLoadComponent>
            <LazyLoadComponent>
                <Technologies/>
            </LazyLoadComponent>
            <LazyLoadComponent>
                <Clients/>
            </LazyLoadComponent>
            <LazyLoadComponent>
                <Partners/>
            </LazyLoadComponent>
            <LazyLoadComponent>
                <Testimonials/>
            </LazyLoadComponent>
            <LazyLoadComponent>
                <EngagModels/>
            </LazyLoadComponent>
            <LazyLoadComponent>
                <Rfq/>
            </LazyLoadComponent>
        </React.Fragment>
    );
}
 
export default LandingPage;