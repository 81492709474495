import React from 'react';
import { at } from 'lodash';
import { useField, ErrorMessage } from 'formik';

export default function TextAreaField (props) {
    const { errorText, type, ...rest } = props;
    const [field, meta] = useField(props);
  
    function _renderHelperText() {
      const [touched, error] = at(meta, 'touched', 'error');
      if (touched && error) {
        return error;
      }
    }
  
    return (
      <React.Fragment>
        {/* <input
          className="form-control mt-4 h-75"
          type={type ? type : "text"}
          error={meta.touched && meta.error && true}
          helperText={_renderHelperText()}
          {...field}
          {...rest}
        /> */}
        <textarea
            rows="7"
            spellCheck="true"
            className="form-control h-100 mt-4"
            {...field}
            {...rest}
          />
        <ErrorMessage name={field.name} style={{color: "#ff0000"}}/>
      </React.Fragment>
    );
  }