import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";

// import { IoChatbubblesOutline } from "react-icons/io5";

import "./chatbotStyle.css";
import { useSpeechSynthesis } from "react-speech-kit";
// import ic_cross from "../../media/chatbot/icons/ic_cross.png";
import ic_cross from "../../media/chatbot/icons/ic_chat.png";
import ic_chat from "../../media/chatbot/icons/ic_chat.png";
import ic_logout from "../../media/chatbot/icons/ic_logout.png";
import ic_mic from "../../media/chatbot/icons/ic_mic.png";
import chatBubble from "../../media/chatbot/icons/chat.png"
import "bootstrap/dist/css/bootstrap.min.css";
import { v4 as uuidv4 } from "uuid";
import Audio from "react-loader-spinner";
import Bars from "react-loader-spinner";
// import { Audio } from 'react-loader-spinner'
import moment from "moment";
import useSpeechToText from "react-hook-speech-to-text";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { VideoView, ChatList } from ".";

import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";

var startTime = null;
var CHAT_SESSOIN = "";
var MIC_IS_RECOGNIZING = false;
var CHATBOT_IS_SPEAKING = false;
var CHAT_START_TIME = "";
const STARTTEXT =
  "Welcome to Digital Landscape. My name is Shahab and I am here to offer you a seamless tour of our products & services that can help you scale your organizational goals to new heights.";

// var NAME = "";
// var AGE = "";
// var GENDER = "";
// var MARITAL = "";
// var DIAGNOSIS = "";

var NAME = "";
var PRODUCT = " ";
var CONTACT = " ";

var CHAT_LIST = [];
var Session = [];
var face_expressionArr = [];
const user = {
  id: uuidv4(),
};
console.log("User id is:", user.id)

const Main = ({ isShown }) => {
  const [isChatOrClear, setIsChatOrClear] = useState(true);
  const [havePermission, setPermission] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isMicEnable, setIsMicEnable] = useState(true);
  const [isChatEnds, setISChatEnds] = useState(false);

  const style = useStyle({ isChatOrClear, isSessionExpired, isMicEnable });
  const videoViewRef = useRef();
  const chatListRef = useRef();

  // const sessionExpireModalRef = useRef();
  // const {
  //   error,
  //   interimResult,
  //   isRecording,
  //   results,
  //   startSpeechToText,
  //   stopSpeechToText,
  // } = useSpeechToText({
  //   continuous: true,
  //   useLegacyResults: false,
  // });

  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  // Time and Date of Chat
  const checkTimeIsRemaining = (startTime) => {
    // start time and end time

    var endTime = moment(new Date().getTime());
    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;

    if (minutes > 9) {
      return false;
    }
    return true;
  };
  function getDateTime() {
    let date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var second = date.getSeconds();

    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    if (month.toString().length == 1) {
      month = "0" + month;
    }
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (hours.toString().length == 1) {
      hours = "0" + hours;
    }
    if (minutes.toString().length == 1) {
      minutes = "0" + minutes;
    }
    if (second.toString().length == 1) {
      second = "0" + second;
    }

    var dateTime =
      day +
      "-" +
      month +
      "-" +
      year +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      second +
      " " +
      ampm;
    return dateTime;
  }

  // Date and TIme ends header
  // Handling Video
  const playVideo = () => {
    videoViewRef.current.playVideo();
  };

  const stopVideo = () => {
    if (videoViewRef.current) {
      videoViewRef.current.stopVideo();
    }
  };
  // Handling Vidoe ends here
  // Handling Speech
  const toggleMic = () => {
    // alert("Hello World")
    stopVideo();
    window.cancelSpeak();
    if (isRecording) {
      stopSpeechToText();
      MIC_IS_RECOGNIZING = false;
    } else {
      startSpeechToText();
      MIC_IS_RECOGNIZING = true;
    }
  };

  const speakByText = (text) => {
    window.speak(text, (isVoiceStated) => {
      if (isVoiceStated) {
        playVideo();
        CHATBOT_IS_SPEAKING = true;
        stopSpeechToText();
      }
      if (!isVoiceStated) {
        stopVideo();
        CHATBOT_IS_SPEAKING = false;
        if (CHATBOT_IS_SPEAKING === false && MIC_IS_RECOGNIZING === true) {
          startSpeechToText();
          MIC_IS_RECOGNIZING = true;
        }
      }
    });
  };

  const speakByText2 = (text, response) => {
    if (text === STARTTEXT) {
      if (response)
        window.speak(text, (isVoiceStated) => {
          if (isVoiceStated) {
            playVideo();
            setTimeout(() => {
              stopVideo();
              setChatMsgs(response);
            }, 3000);
          }
        });
    } else {
      if (response) {
        window.speak(text, (isVoiceStated) => {
          if (isVoiceStated) {
            playVideo();

            if (text === STARTTEXT) {
              setTimeout(() => {
                stopVideo();
                setChatMsgs(response);
              }, 5000);
            }
          }
          if (!isVoiceStated) {
            stopVideo();
            setChatMsgs(response);
          }
        });
      }
    }
  };

  // Handling speech ends here
  // Clear Session
  const clearSession = () => {
    stopVideo();
    window.cancelSpeak();
    startTime = "";
    CHAT_SESSOIN = "";
    CHAT_SESSOIN = uuidv4();
  };
  // Clear session Ends here

  // Save Details Starts here
  // const redirectResponse={
  //   data:{
  //     english: "That's Great. I have opened the related form in new tab. Please fill that form"
  //   }
  // }

  const checkForActions = (userPreviousMsg, newChatBotData) => {
    if (newChatBotData?.action !== "") {
      // alert("Action is",newChatBotData?.action)
      switch (newChatBotData?.action) {
        case "Save user name":
          NAME = userPreviousMsg;
          break;
        case "Save product name":
          PRODUCT = userPreviousMsg;
          break;
        case "Open URL":
          let url = newChatBotData?.english;
          window.open(url, "_blank").focus();
          // setChatMsgs(redirectResponse);
          break;
        case "Save contact and send email":
          CONTACT = userPreviousMsg;
          sendEmail("REQUEST FOR DEMO");
          break;
        case "End Chat":
          //  alert("Hello")
          // setISChatEnds(true);
          // setTimeout(function () {
          //       setISChatEnds(true);
          //     }, 8000);
          //     chatListRef.current.clearChat();
          setTimeout(function () {
            endChat()
          }, 8000);
          break;
        // case "Marital":
        //   MARITAL = userPreviousMsg;
        //   break;
        // case "Diagnosis":
        //   DIAGNOSIS = newChatBotData?.save_user_disgnosis;
        //   break;

        default:
          break;
      }
    }
  };

  // Call backs start here
  const toggleChatOrClearChat = () => {
    setIsChatOrClear((isChatOrClear) => !isChatOrClear);
  };
  const onInputNewMessageCallback = useCallback((newInputMsg) => {
    sendMessageToChatBot(newInputMsg);
  });

  const onSelectTextCallback = useCallback((text) => {
    let msg = {
      button: [],
      isClient: true,
      text_eng: text,
      msgTime: getDateTime(),
    };
    chatListRef.current.addMessage(msg);
    sendMessageToChatBot(text);
  }, []);

  const onSessionEndTriggerCallback = useCallback(() => {
    // sendChatListToChatBot();
  }, []);

  const onStopVideoAndVoiceCallback = useCallback(() => {
    stopVideo();
    window.cancelSpeak();
  }, []);

  // call backs ends here

  let index = 0;
  let speachTime = 0;

  let speachText = "";
  const setChatMsgs = async (response) => {
    // if (
    //   response.data.english ===
    //   "I feel great talking to you. I hope to see you soon. Good Bye! "
    // ) {
    //   setTimeout(function () {
    //     setISChatEnds(true);
    //   }, 3000);
    // }

    if (response.data?.english.length > 1) {
      if (index <= response.data?.english.length - 1) {
        chatListRef.current.inputDisable();
        setIsMicEnable(false);

        // setTimeout(async () => {

        speachText += response.data?.english[index] + ",";

        let msg = {
          button: response.data?.button,
          isClient: false,
          text_eng: response.data?.english[index],
          msgTime: getDateTime(),
        };
        chatListRef.current.addMessage(msg);
        startTime = new moment(new Date().getTime());
        setTimeout(() => {
          chatListRef.current.scrollUp();
        }, 500);

        if (index === response.data?.english.length - 1) {
          chatListRef.current.inputEnable();
          setIsMicEnable(true);
        }
        if (index === response.data?.english.length) {
          speakByText2(speachText, null);
          if (response.data?.button.length < 1) {
            chatListRef.current.inputEnable();
          }
        } else {
          speakByText2(response.data?.english[index], response);
        }
        index++;
        // }, 1000);
      } else {
        index = 0;
        // speachTime = 0
      }
    } else {
      speakByText(response.data?.english[0]);

      let msg = {
        button: response.data?.button,
        isClient: false,
        text_eng: response.data?.english[0],
        msgTime: getDateTime(),
      };
      chatListRef.current.addMessage(msg);
      startTime = new moment(new Date().getTime());
      setTimeout(() => {
        chatListRef.current.scrollUp();
      }, 500);
      if (response.data?.button.length < 1) {
        chatListRef.current.inputEnable();
        setIsMicEnable(true);
      }
    }
  };

  // Send message to chatbot APi
  const sendMessageToChatBot = (msg) => {
    if (CHAT_START_TIME === "") {
      CHAT_START_TIME = getDateTime();
    }

    window.cancelSpeak();
    stopVideo();

    if (checkTimeIsRemaining(startTime)) {
      var users = JSON.parse(localStorage.getItem("userVs"));
      let userTime = getDateTime();
      // debugger;
      let requestBody = {
        userInput: msg,
        sessionID: user.id,
        // id_token: users.id_token,
      };
      console.log("USer object request Body", requestBody);

      const requestOptions = {
        method: "POST",
        body: requestBody,
      };

      axios({
        method: "POST",
        url: "https://chatbot.digitallandscape.com.pk:5012/get_bot_response",
        data: requestBody,
      })
        .then(
          function (response) {
            // console.log("Get Bot response session status");
            // console.log("Get Bot response", response.data);
            if (
              typeof response.data.message !== "undefined" &&
              response.data.message === "INVALID_ID_TOKEN"
            ) {
              try {
                // RefreshToken().then((res) => {
                // if (
                //   typeof res.data.message !== "undefined" &&
                //   res.data.message === "TOKEN_REFRESHED"
                // ) {
                //   users.id_token = res.data.data[0].new_id_token;
                //   localStorage.setItem("userVs", JSON.stringify(users));
                //   sendMessageToChatBot(msg);
                // }
                // });
              } catch (e) {
                console.log(e);
              }
            } else if (response.data.is_session_ended === true) {
              // Calling APi function

              // setTimeout(() =>  sendChatListToChatBot(), 8000);
              // sendChatListToChatBot();user

              setChatMsgs(response);
              CHAT_LIST.push({
                user_time: userTime,
                bot_time: getDateTime(),
                user: msg,
                bot: response.data?.english,
              });
              console.log("Chat at line 627 is:", CHAT_LIST);
              console.log("Session Array is", Session);
              // checkForActions(msg, response?.data);
            } else {
              // if(response.data.action!=""){
              //   // alert("Link here")

              // }
              // else{

              CHAT_LIST.push({
                user_time: userTime,
                bot_time: getDateTime(),
                user: msg,
                bot: response.data?.english,
              });
              setTimeout(function () {
                setChatMsgs(response);
              }, 2000);

              // console.log("Chat at line 627 is:", CHAT_LIST);
              // console.log("Session Array in else is", Session);
            }

            checkForActions(msg, response?.data);
          }
          // setIsChatOrClear(true);
          // }
        )
        .catch(function (error) {
          // console.log(error);
          // alert(error);
        });
    } else {
      // setIsSessionExpired(true)
      // sessionExpireModalRef.current.show();
      // alert("Session Ended");
    }
  };

  const startConversation = () => {
    // if (CHAT_LIST.length == 0) {
    //    CHAT_LIST.push("Hello Word")
    //     alert("empty");

    // }
    sendMessageToChatBot("Hello");
    
      setISChatEnds(false);
    // const element = document.getElementById("helloText");
    // element.remove();
  };

  // Send Email TO DLS
  const sendEmail = (emailSubject) => {
    debugger;
    console.log("User details Name is " + PRODUCT + " with Contact " + CONTACT);

    let requestBody = {
      emailSubject: emailSubject,
      userEmail:CONTACT,
      emailBody:
        "A user is Asking for Demo \n\n Person Name:" +
        NAME +
        "\n Product: " +
        PRODUCT +
        "\n Contact:" +
        CONTACT,
    };
    axios({
      method: "POST",
      url: "https://chatbot.digitallandscape.com.pk:5012/send_email",
      data: requestBody,
    })
      .then(function (response) {
        console.log("Response on Eeail send ", response);
      })
      .catch(function (error) {
        console.log("Error is here");
      });
  };

  const endChat=()=>{

      setISChatEnds(true);
    chatListRef.current.clearChat();
  }

  useEffect(() => {
    results.map((result, index) => {
      if (index === results.length - 1) {
        let msg = {
          button: [],
          isClient: true,
          text_eng: result?.transcript,
          msgTime: getDateTime(),
        };
        chatListRef.current.addMessage(msg);
        sendMessageToChatBot(result?.transcript);
      }
    });
  }, [results]);

  return (
    <div>
      <div className={isShown ? "MainContainer" : "MainContainerHidden"}>
        <div className="botHeader">
          {/* <Bot/> */}
          {/* <VideoView ref={videoViewRef} id="myVideo" chatIsClear={isChatOrClear} /> */}
          Digital Landscape <span className="span-green">.</span>
        </div>
        <div className="botArea">
          {/* <Bot/> */}
          <VideoView
            ref={videoViewRef}
            id="myVideo"
            chatIsClear={isChatOrClear}
          />
        </div>

        <Container className={style.inputcontainer}>
          {CHAT_LIST.length == 0 && (
            <div className={style.helloText} id="helloText">
              <img src={chatBubble} className={style.chatBubble}/>
              <p>
                You have not Spoken Yet.
                <br /> I'm here to Help you
              </p>
              <button className={style.helloButton} onClick={startConversation}>
                Let's Talk
              </button>
            </div>
          )}

          {isChatEnds && (
            <div className={style.helloText} id="reHelloText">
                <img src={chatBubble} className={style.chatBubble}/>
              <p>
                It's really nice talk to You.
              <br/> I'm sure I'll be back if I need more help.
              </p>
              <button className={style.helloButton} onClick={startConversation}>
                Talk Again
              </button>
            </div>
          )}

          <Row className="chatRow">
            <Col xs={10}>
              <div className={style.chatContainer}>
                <ChatList
                  ref={chatListRef}
                  chatIsClear={isChatOrClear}
                  closechat={toggleChatOrClearChat}
                  onInputNewMessage={onInputNewMessageCallback}
                  onSelectText={onSelectTextCallback}
                  onSessionEndTrigger={onSessionEndTriggerCallback}
                  onStopVideoAndVoice={onStopVideoAndVoiceCallback}
                />
              </div>
            </Col>
            <Col xs={2}>
              {/* <div
                  className={style.micButtonMobile}
                  id="micButtonMobile"
                  onClick={() => {
                    toggleMic();
                  }}
                >
                  {isRecording ? (
                    <Bars
                      heigth="100%"
                      width="100%"
                      color="#429EFA"
                      ariaLabel="loading"
                    />
                  ) : (
                    <img src={ic_mic} className={style.micButtonImage} />
                  )}
                </div> */}
                {/* <div className="position-absolute bottom-0 end-0"> */}
              <div className={style.rightBottomBtnContainer}>
                <div
                  className={style.micButtonContainer}
                  id="micButtonContainer"
                  onClick={() => {
                    toggleMic();
                  }}
                >
                  {isRecording ? (
                    <Bars
                      heigth="100%"
                      width="100%"
                      color="#429EFA"
                      ariaLabel="loading"
                      className={style.barsButtonImage}
                    />
                  ) : (
                    <img src={ic_mic} className={style.micButtonImage} />
                  )}
                </div>
                {/* <div
              className={style.chatImgButtonContainer}
              onClick={toggleChatOrClearChat}
            >
              <img
                src={isChatOrClear ? ic_cross : ic_chat}
                className={style.chatImgButton}
              />
            </div> */}
              </div>
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    // </div>
  );
};

const useStyle = createUseStyles({
  mainContainer: {
    display: "flex",
    top: "0%",
    left: "0%",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    position: "absolute",
  },
  chatContainer: {
    // minWidth: '40%',
    // width: '40%',
    marginLeft: "0",
    // padding: "0 -20px",
    width: (props) => (props.isChatOrClear ? "100%" : "0%"),
    // height: "100vh",
    opacity: (props) => (props.isChatOrClear ? 1 : 0),
    display: "flex",
    // justifyContent: "end",
  },
  rightBtnContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    minWidth: "15%",
    paddingRight: "10px",
  },
  rightTopBtnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  rightBottomBtnContainer: {
    // marginBottom: "30px",
    // marginLeft: "10px",
  },
  logoutButtonContainer: {
    marginTop: "10px",
    background: "white",
    padding: "1.6vh",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  micButtonMobile: {
    width: "6.5vh",
    height: "6.5vh",
    // background: "white",
    background: "transparent",
    padding: "1vh",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    pointerEvents: (props) => (props.isMicEnable ? "auto" : "none"),
    backgroundColor: (props) => (props.isMicEnable ? "white" : "#00000010"),
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  inputcontainer: {
    // backgroundColor: "black",
    // height:"80%"
    height: "320px",
    border: "2px solid #1A8DFF",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "12px 6px 12px #0000001F",
    borderRadius: "10px 10px 0px 0px ",
    //  overflow: "hidden",
  },
  chatRow:{
    overflow: "hidden"
  },
  micButtonContainer: {
    // marginTop: "250px",
    position: "absolute",
    bottom: "2vh",
    right: "2vh",
    // marginTop: "39vh",
    width: "6.5vh",
    height: "6.5vh",
    background: "white",
    // padding: "1vh",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: (props) => (props.isMicEnable ? "auto" : "none"),
    backgroundColor: (props) => (props.isMicEnable ? "white" : "#00000010"),
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  brainButtonContainer: {
    background: "white",
    // padding: '1%',
    borderRadius: "100%",
    top: "2%",
    left: "1%",
    position: "absolute",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  chatImgButtonContainer: {
    background: "white",
    padding: "1.8vh",
    marginTop: "5px",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  chatImgButton: {
    width: "3vh",
    height: "3vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  logoutButtonImage: {
    width: "3.5vh",
    height: "3.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  micButtonImage: {
    width: "3.5vh",
    height: "3.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  barsButtonImage: {
    width: "3.5vh",
    height: "3.5vh",
    // objectFit: "contain",
    // alignSelf: "center",
    position: "absolute",
    bottom: "4.2vh",
    right: "1.8vh",
  },

  brainButtonImage: {
    width: "6.5vh",
    height: "6.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  chatExpiryScreen: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "transparent",
    position: "absolute",
    left: 0,
    zIndex: 12000,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    transition: "top 0.5s ease-in-out",
    top: (props) => (props.isSessionExpired ? 0 : "-100%"),
  },
  chatExpiryPopupView: {
    width: "20%",
    height: "30%",
    backgroundColor: "white",
    alignSelf: "center",
    borderRadius: "8%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  sessionModalHeader: {
    textAlign: "center",
  },
  sessionModalDesc: {
    textAlign: "center",
    color: "#00000050",
  },
  sessionModalButton: {
    width: "12vh",
    height: "4vh",
    color: "white",
    alignSelf: "center",
    backgroundColor: "black",
    borderColor: "transparent",
    borderRadius: 100,
  },
  brainButtonImage: {
    width: "7vh",
    height: "7vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  helloText: {
    fontSize: "12pt",
    textAlign: "center",
    marginTop: "20%",
    fontFamily: "Georgia",
    // marginBottom:"50%"
  },
  reHelloText: {
    fontSize: "12pt",
    textAlign: "center",
    marginTop: "30%",
    fontFamily: "Georgia",
    // marginBottom:"50%"
  },
  helloButton: {
    fontSize: "12pt",
    // padding:"10px",
    borderColor: "transparent",
    maxWidth: "100%",
    padding: "2vh",
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#1A8DFF",
    borderTopLeftRadius: "4vh",
    borderTopRightRadius: "4vh",
    borderBottomRightRadius: "0",
    borderBottomLeftRadius: "4vh",
    fontSize: "15px",
    margin: "0.7vh",
    wordWrap: "break-word",
    lineHeight: 1.5,
    webkitBoxShadow:
      "rgb(74 79 97 / 16%) 0px 10px 10px -5px, rgb(74 79 97 / 30%) 0px 8px 9px -8px",
    mozBoxShadow:
      "rgb(74 79 97 / 16%) 0px 10px 10px -5px, rgb(74 79 97 / 30%) 0px 8px 9px -8px",
    boxShadow:
      "rgb(74 79 97 / 16%) 0px 10px 10px -5px, rgb(74 79 97 / 30%) 0px 8px 9px -8px",
  },
  chatBubble:{
    width: "9.5vh",
    height: "9.5vh",
    // background: "white",
    background: "transparent",
  }
});

export default Main;
