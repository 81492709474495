import { useSpeechRecognition } from "react-speech-kit";
import ic_send from "../../media/chatbot/icons/ic_send.png";
import Emojify from "react-emojione";

import { Button, Input, Image } from "antd";
import React, {
  useEffect,
  useRef,
  useState,
  memo,
  useImperativeHandle,
  useCallback,
} from "react";
import { createUseStyles } from "react-jss";
import ReactEmoji from "react-emoji";

import { ChatListButtonsBox } from ".";

const ChatList = ({
  item,
  index,
  onSelectText,
  messageListLength,
  onEndSession,
}) => {
  const style = useStyle();

  // console.log('index : ', index)

  useEffect(() => {}, []);

  const onSelectTextCallback = useCallback((text) => {
    onSelectText(text);
  }, []);

  const onEndSessionFromBackendCallback = useCallback(() => {
    onEndSession();
  }, []);

  const ListItem = ({ item, index }) => {
    // if (item.text_eng !== 'Hello Doctor') {

    let time = item?.msgTime.split(" ");

    if (item.isClient) {
      return (
        <li key={index} style={{ padding: 0, margin: 0 }}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <span className={style.clientTimeLabel} id="clientTimeLabel">
              {time[1] + " " + time[2]}
            </span>
            <div className={style.chatitemLabelContainerClient}>
              <div className={style.emptyViewForClientItem} />
              {!item?.text_eng.includes(":") ? (
                <h4
                  className={style.clientMsgLabel}
                  id="clientMsgLabel"
                  onClick={() => {
                    // alert(item?.msgTime);
                  }}
                >
                  {item.text_eng}
                </h4>
              ) : (
                <Emojify style={{ height: "50%", width: "50%" }}>
                  <span className={style.clientMsgLabel}>{item.text_eng}</span>
                </Emojify>
              )}
            </div>
          </div>
        </li>
      );
    } else {
      return (
        <li key={index} style={{ padding: 0, margin: 0 }}>
          <span className={style.chatbotTimeLabel} id="chatbotTimeLabel">
            {time[1] + " " + time[2]}
          </span>
          <div
            className={style.chatitemLabelContainerChatBot}
            id="chatitemLabelContainerChatBot"
          >
            <h4 className={style.chatbotMsgLabel} id="chatbotMsgLabel">
              {item.text_eng}
            </h4>
          </div>

          <ChatListButtonsBox
            onEndSessionFromBackend={onEndSessionFromBackendCallback}
            messageListLength={messageListLength}
            index={index}
            buttons={item.button}
            onSelectText={onSelectTextCallback}
          />
        </li>
      );
    }
    // } else {
    //     return (null)
    // }
  };

  return <ListItem key={index} item={item} index={index} />;
};

const useStyle = createUseStyles({
  chatitemLabelContainerChatBot: {
    maxWidth: "100%",
    flexDirection: "row",
    display: "flex",
    marginBottom: "1vh",
  },
  chatitemLabelContainerClient: {
    maxWidth: "100%",
    flexDirection: "row",
    display: "flex",
    marginBottom: "1vh",
  },
  clientMsgLabel: {
    maxWidth: "100%",
    padding: "2vh",
    color: "black",
    fontWeight: "normal",
    backgroundColor: "#ffff",
    borderTopLeftRadius: "4vh",
    borderTopRightRadius: 0,
    borderBottomRightRadius: "4vh",
    borderBottomLeftRadius: "4vh",
    fontSize: "15px",
    margin: "0.7vh",
    wordWrap: "break-word",
    lineHeight: 1.5,
    webkitBoxShadow:
      "rgb(74 79 97 / 16%) 0px 10px 10px -5px, rgb(74 79 97 / 30%) 0px 8px 9px -8px",
    mozBoxShadow:
      "rgb(74 79 97 / 16%) 0px 10px 10px -5px, rgb(74 79 97 / 30%) 0px 8px 9px -8px",
    boxShadow:
      "rgb(74 79 97 / 16%) 0px 10px 10px -5px, rgb(74 79 97 / 30%) 0px 8px 9px -8px",
  },
  clientTimeLabel: {
    color: "#111212",
    fontWeight: "normal",
    fontSize: "11px",
    wordWrap: "break-word",
    alignSelf: "flex-end",
    // backgroundColor: '#ffffff50',
    padding: 1,
    borderRadius: 10,
    marginRight: "2%",
    fontWeight: "600",
  },
  chatbotTimeLabel: {
    color: "#111212",
    fontWeight: "normal",
    fontSize: "11px",
    wordWrap: "break-word",
    alignSelf: "flex-end",
    // backgroundColor: '#ffffff50',
    padding: 1,
    borderRadius: 10,
    marginLeft: "2%",
    fontWeight: "600",
  },
  chatbotMsgLabel: {
    maxWidth: "100%",
    padding: "2.5vh",
    color: "white",
    fontWeight: "normal",
    backgroundColor: "#429EFA",
    borderTopLeftRadius: 0,
    borderTopRightRadius: "5vh",
    borderBottomRightRadius: "5vh",
    borderBottomLeftRadius: "5vh",
    fontSize: "15px",
    margin: "0.7vh",
    wordWrap: "break-word",
    lineHeight: 1.5,
    webkitBoxShadow:
      "rgb(74 79 97 / 16%) 0px 10px 10px -5px, rgb(74 79 97 / 30%) 0px 8px 9px -8px",
    mozBoxShadow:
      "rgb(74 79 97 / 16%) 0px 10px 10px -5px, rgb(74 79 97 / 30%) 0px 8px 9px -8px",
    boxShadow:
      "rgb(74 79 97 / 16%) 0px 10px 10px -5px, rgb(74 79 97 / 30%) 0px 8px 9px -8px",
  },
  emptyViewForClientItem: {
    flex: 1,
  },
  inputBox: {
    width: "100%",
    height: "8vh",
    backgroundColor: "white",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    paddingLeft: "1vw",
    paddingRight: "1vw",
    borderRadius: 500,
  },
  inputFiled: {
    flex: 1,
    fontSize: "1vw",
    padding: "1vw",
    backgroundColor: "white",
    borderColor: "transparent",
    borderTopLeftRadius: "5vw",
    borderTopRightRadius: "5vw",
    borderBottomRightRadius: "5vw",
    borderBottomLeftRadius: "5vw",
    margin: "1vw",
    "&:hover": {},
    "&:active": {
      borderColor: "white",
      borderWidth: 0,
    },
    outline: "none",
  },
  sendButton: {
    // backgroundColor: '#429EFA',
    // color: 'white',
    // fontSize: '1vw',
    width: "2vw",
    height: "2vw",
    // borderTopLeftRadius: '5vw',
    // borderTopRightRadius: '5vw',
    // borderBottomRightRadius: '5vw',
    // borderBottomLeftRadius: '5vw',
    // borderColor: 'transparent',
    margin: "0.5vw",
    padding: "0.5vw",
  },
  micContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
  },
  micButton: {
    width: "2.5vw",
    height: "2.5vw",
    marginRight: "0.5vw",
    marginLeft: "0.5vw",
    alignSelf: "center",
    background: "white",
    border: 0,
    background: "transparent",
  },
});

export default memo(ChatList);
