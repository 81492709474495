import React from "react";
import Header from "../components/header.jsx";
import Rfq from "../components/rfq.jsx";
import { useLocation } from "react-router-dom";

const RequestQuote = () => {
    const location = useLocation();
    return ( 
        <React.Fragment>
            <Header locationName={location.pathname}/>
            <Rfq/>
        </React.Fragment>
    );
}
 
export default RequestQuote;